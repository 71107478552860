<template>
  <div
    v-show="Counts > 0"
    class="common-reviews j-expose__common-review-container sticky-under-gallery"
  >
    <!-- 评论平均分 -->
    <AverageRate
      direction="horizontal"
      position="popup"
      :panel-show="showRatePanel"
      :percent-rate-data="comments_overview"
      :review-policy-info="review_policy_info"
      :reviews-abt="reviews_abt"
      :screen-abt-tsp-config="screen_abt_tsp_config"
    />
    <!-- 本地评论开关 -->
    <ShowLocalSwitch 
      v-if="show_local_reviews && Counts > 0"
      :comment-local="comment_overview_local"
      :comment="comments_overview"
      @change="changeLocalComment"
    />
    <!-- 评论筛选tab -->
    <div
      v-if="Counts > 0"
      v-expose="{ id: '1-69-1-2', data: exposeFilterData }"
      class="common-reviews__select"
    >
      <!-- [All Reviews/Image/Free Trial Report] tab -->
      <div class="common-reviews__select-tab j-expose__review-tab-container">
        <span
          class="tab"
          :class="{'active': !fetchParams.is_picture && tabType === 'review'}"
          @click="clickReview('review', 'is_picture', '')"
        >
          {{ language.SHEIN_KEY_PC_17108 }}
        </span>
        <span
          class="tab j-expose__review-image-tab-target"
          :class="{'active': fetchParams.is_picture && tabType === 'image'}"
          @click="clickReview('image', 'is_picture', 1)"
        >{{ language.SHEIN_KEY_PC_14671 }}</span>
        <span
          v-if="trail_data.length > 0 && !fetchParams.tag_id"
          class="tab"
          :class="{'active': tabType === 'freeTrail'}"
          @click="clickReview('freeTrail')"
        >
          {{ language.SHEIN_KEY_PC_15756 }}
        </span>
      </div>

      <!-- Rating&Filter tab -->
      <div 
        class="common-reviews__select-wrap"
      >
        <!-- Rating -->
        <div class="common-reviews__select-item">
          <span>{{ language.SHEIN_KEY_PC_15166 }}</span>
          <div class="common-reviews__select-box">
            <div class="common-reviews__select-box-input">
              <span v-if="fetchParams.comment_rank">{{ fetchParams.comment_rank }} {{ language.SHEIN_KEY_PC_17329 }}</span>
              <span v-else>{{ language.SHEIN_KEY_PC_14677 }}</span>
              <sui_icon_more_down_14px />
            </div>
            <ClientOnly>
              <ul class="common-reviews__select-box-list">
                <li
                  v-if="fetchParams.comment_rank"
                  @click="clickFilter('comment_rank', '')"
                >
                  {{ language.SHEIN_KEY_PC_14677 }}
                </li>
                <template v-for="(item, index) in filter.rating">
                  <li
                    v-if="fetchParams.comment_rank !== item"
                    :key="index"
                    @click="clickFilter('comment_rank', item)"
                  >
                    {{ item }} {{ language.SHEIN_KEY_PC_17329 }}
                  </li>
                </template>
              </ul>
            </ClientOnly>
          </div>
        </div>

        <!-- Filter/Review by product -->
        <ReviewFilter
          :selected-state="fetchParams"
          :colors="colorsList"
          :sizes="sizeList"
          @change="(type, params) => clickFilter(type, params)"
        />
        
        <!-- Sort by -->
        <div class="common-reviews__select-item">
          <span>{{ language.SHEIN_KEY_PC_14672 }}</span>
          <div class="common-reviews__select-box">
            <template v-for="(item, index) in filter.sort">
              <div
                v-if="item.value === fetchParams.sort"
                :key="index"
                class="common-reviews__select-box-input"
              >
                <span>{{ item.title }}</span>
                <sui_icon_more_down_14px />
              </div>
            </template>
            <ClientOnly>
              <ul class="common-reviews__select-box-list">
                <template v-for="(item, index) in filter.sort">
                  <li
                    v-if="item.value !== fetchParams.sort"
                    :key="index"
                    :class="{'active': item.value === fetchParams.sort}"
                    :data-sort-type="item.title"
                    @click="clickFilter('sort', item.value)"
                  >
                    {{ item.title }}
                  </li>
                </template>
              </ul>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>

    <!-- 评论标签筛选 -->
    <ReviewsTag
      v-if="reviews_tags.length && tabType !== 'freeTrail' && Counts > 0"
      :tag-list="reviews_tags"
      :active-id="fetchParams.tag_id"
      @review-tag-id-change="handleTagClick"
    />



    <!-- 批量翻译 -->
    <ClientOnly>
      <div
        v-if="is_batch_translate"
        v-expose="{ id: '1-8-5-59' }"
        class="batch-translate"
      >
        <p
          class="batch-translate__text batch-translate__text-blue"
          @mouseenter="showTranslateLang = true"
        >
          {{ languageMap[default_lang] }}
          <sui_icon_more_down_14px />
          <ul
            v-show="showTranslateLang"
            @mouseleave="showTranslateLang = false"
          >
            <template
              v-for="(language, index) in language_config_set"
              :key="index"
            >
              <li @click.stop.prevent="changeLanguage(language)">
                <button
                  class="translate-button"
                  :class="{
                    'translate-lang': language === default_lang
                  }"
                  tabindex="0"
                  role="botton"
                  :aria-label="languageMap[language]"
                >
                  {{ languageMap[language] }}
                </button>
              </li>
            </template>
          </ul>
        </p>
        <p class="batch-translate__text">
          {{ language.SHEIN_KEY_PC_19659 }}
        </p>
        <s-checkbox
          v-model="isChecked"
          size="size16"
          margin-r="0"
          label="Checked"
          @change="handleChange"
        />
      </div>
    </ClientOnly>
    <!-- 评论列表 -->
    <div
      v-infinite-scroll="fectMoreComment"
      infinite-scroll-disabled="commentScrollLoading"
      infinite-scroll-nodata="noMoreComment"
      :infinite-scroll-distance="30"
    >
      <div class="common-reviews__list j-expose__common-reviews__list">
        <div
          v-for="(item, index) in renderCommentList"
          :key="item.comment_id"
          v-expose="getAnalysisData(item, index)"
          class="j-expose__common-reviews__list-item"
          :data-expose-id="getExposeId(index, item.comment_id)"
        >
          <Comment
            position="popup"
            :class="`j-expose__common-${item.reportId ? 'report' : 'reviews'}__list-item-con`"
            :comment="setCommentData(item, index)"
            :language="language"
            :fit-map="fit_map"
            :has-fit="has_fit"
            :analysis="{ activity_from: 'page_goods_detail', location: 1 }"
            :skc="detail.sku"
            :show-point-program="show_point_program"
            :screen-abt-config="reviews_abt"
            @image-click="clickCommentImage"
            @like-click="likeClickHandle"
          >
            <template
              v-if="item.translate_language.length > 0"
              #ContentBottom
            >
              <CommentTranslate
                :comment-translate-cache="commentTranslateCache"
                :curr-translate-lang="currTranslateLang"
                :translateloading="translateloading"
                :translate-model="translateModel"
                :comment="item"
                :spu="item.spu"
                :language="language"
                :is-batch-translate="is_batch_translate"
                :is-auto-translate="is_auto_translate"
                :temp-comment="tempComment"
                from="review"
                @update-temp-comment="updateTempComment"
                @close="translateClose"
                @refresh-loading="refreshLoading"
                @refresh-translate-cache="refreshTranslate"
                @refresh-translate-lang="refreshTranslateLang"
                @refresh-modal="refreshModal"
              />
            </template>
          </Comment>
        </div>
      </div>
    </div>


    <!-- Some reviews have been folded for you -->
    <div
      v-if="shouldHideReviewTipsShow"
      class="common-reviews__fuzzy-tips"
    >
      <p>{{ language.SHEIN_KEY_PC_26900 }}</p>
    </div>

    <!-- <div
      v-if="isAbtCommentAntiSpiderShowToLogin"
      v-expose="{ id: '1-8-5-56' }"
      v-tap="{ id: '1-8-5-57' }"
      class="common-reviews__tologin"
      @click="handleShowCommentToLogin"
    >
      <div class="common-reviews__tologin_text">
        <span>{{ language.SHEIN_KEY_PC_26899 }}</span>
        <Icon
          name="sui_icon_more_right_18px"
          size="16"
          class="tologin-icon"
        />
      </div>
    </div> -->
    <div class="common-reviews__oms">
      <div
        v-if="fetchParams.sort && ['review', 'image'].includes(tabType) && reportList.length"
        class="common-reviews__reportip"
      >
        <Icon
          name="sui_icon_message_warning_16px"
          size="16"
          class="reportip-icon"
        />
        {{ language.SHEIN_KEY_PC_17706 }}
      </div>
    </div>

    <!-- 评论弹窗 -->
    <ClientOnly>
      <CustomerReviewsImageModal
        v-model:value="model.show"
        :comment-id="model.commentId"
        :fetch-params="model.fetchParams"
        :index="model.index"
        :comment-images="model.commentImages"
        :comment-info="model.comments"
        :all-comment-info="reviewList"
        :click-mode="model.clickMode"
        :report-list="allReportList"
        :free-trail-index="model.freeTrailIndex"
        :free-trail-sub-index="model.freeTrailSubIndex"
        :fit-map="fit_map"
        :handle-size="handleSize"
        :language="language"
        :comment-translate-cache="commentTranslateCache"
        :comment-translate-config="commentTranslateConfig"
        :curr-translate-lang="currTranslateLang"
        :translateloading="translateloading"
        :translate-model="translateModel"
        :is-show-bra-size="isShowBraSize"
        :has-fit="has_fit"
        :temp-comment="tempComment"
        :is-batch-translate="is_batch_translate"
        :is-auto-translate="is_auto_translate"
        :default-lang="default_lang"
        :location="1"
        :skc="detail.sku"
        :is-group-same="isGroupSame"
        :is-main-goods-lowest-price="detail.isMainGoodsLowestPrice"
        :buy-box-same-id="detail.buyBoxSameId"
        :main-goods-id="detail.goods_id"
        :screen-abt-config="reviews_abt"
        @refresh-model-images="refreshModelImages"
        @refresh-model-comment="refreshModelComment"
        @feedback="reviewFeedback"
        @close="translateClose"
        @refresh-loading="refreshLoading"
        @refresh-translate-cache="refreshTranslate"
        @refresh-translate-lang="refreshTranslateLang"
        @refresh-modal="refreshModal"
      />
    </ClientOnly>
  </div>

  <SFeedback
    v-if="!renderCommentList.length || Counts == 0"
    class="common-reviews__null"
    :content="language.SHEIN_KEY_PC_15962"
  >
    <template #icon>
      <Icon
        name="sui_icon_empty_75px_1"
        size="75px"
        class="null-icon"
      />
    </template>
  </SFeedback>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Comment from './Comment'
import CommentTranslate from './CommentTranslate'
import ShowLocalSwitch from './ShowLocalSwitch'
import ReviewsTag from './ReviewsTag'
import ReviewFilter from './ReviewFilter'
import CustomerReviewsImageModal from './CustomerReviewsImageModal'
import AverageRate from './AverageRate'
import { SFeedback } from '@shein-aidc/sui-feedback'
import { daEventCenter } from 'public/src/services/eventCenter/index.js'
import { abtservice } from 'public/src/services/abt/index.js'
import schttp from 'public/src/services/schttp'
import { COMMENT_FUZZY_LIMIT, LOCAL_COMMENT_ABT_FLAG_MAP } from 'public/src/pages/goods_detail_v2/constants.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { 
  Icon,
  sui_icon_more_down_14px
} from '@shein-aidc/icon-vue3'
import commentAdapter from 'public/src/pages/goods_detail_v2/utils/adapters/comment.js'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { makeFreeList } from 'public/src/pages/goods_detail_reviews/store/utils.js'

// daEventCenter.addSubscriber({ modulecode: '1-8-4' })
daEventCenter.addSubscriber({ modulecode: '1-69-1' })

const { lang, PUBLIC_CDN, SiteUID } = gbCommonInfo

export default {
  name: 'ReviewsPage',
  emits: ['show-model', 'hide-comment', 'only-store-comment'],
  components: {
    sui_icon_more_down_14px,
    ClientOnly,
    AverageRate,
    ShowLocalSwitch,
    Icon,
    ReviewFilter,
    ReviewsTag,
    Comment,
    CommentTranslate,
    SFeedback,
    CustomerReviewsImageModal,
  },
  provide() {
    return {
      REPORT_DATA: {
        relativeSpu: this.detall?.goods_spu,
        categoryId: this.detall?.cat_id || '',
      },
    }
  },
  data() {
    return {
      position: 'top-end',
      visible: true,
      timer: null,
      // language: {},
      options: [],
      //
      lang,
      PUBLIC_CDN,
      SiteUID,
      // Counts: null,
      ImageCounts: null, // 有图评论数
      // imageFuzzy: false,
      // imageCountsStr: '',
      reportCount: null, // 试用报告数
      total: 0,
      totalFuzzy: false,
      totalStr: '0',
      // reviewList: [],
      reportList: [],
      outStoreReviewList: [],
      reportPage: 1,
      mixStoreReviewPage: 1,
      tabType: 'review',
      shouldFetchReportList: true, // 是否需要从接口获取试用报告数据
      noRefresh: true,
      reviewCurrSortTotal: 0, // 保存当前筛选评论的总条数，用来计算review + report的非请求分页的索引
      buyBoxTotal: 0, // 同款组拼接评论数量
      comment: {
        comment_num: 0,
        comment_rank_average: 0,
        percent_overall_fit: {
          large: '',
          small: '',
          true_size: ''
        }
      },
      filter: {
        colors: [],
        sizes: [],
        sort: [
          {
            title: 'Recommend',
            value: ''
          },
          {
            title: 'Most Recent to Oldest',
            value: 'time_desc'
          },
          {
            title: 'Oldest to Most Recent',
            value: 'time_asc'
          },
        ],
        rating: [5, 4, 3, 2, 1]
      },
      fetchParams: {
        spu: '',
        goods_id: '',
        page: 2,
        limit: 20,
        offset: 0,
        sort: '',
        size: '',
        is_picture: '',
        comment_rank: '',
        rule_id: '',
        local_site_query_flag: '',
        local_site_abt_flag: '' // abt
      },
      model: {
        show: false,
        commentImages: [],
        comments: {},
        clickMode: 'review',
        freeTrailIndex: 0,
        freeTrailSubIndex: 0,
        fetchParams: {
          spu: '',
          goods_id: '',
          page: 1,
          limit: 20,
          offset: 0,
          sort: '',
          size: '',
          is_picture: '',
          rule_id: ''
        }
      },
      clickFilterType: '', // 埋点上报用
      feedbacking: false,
      exposeDate: null,
      isShowBraSize: false,
      reviewsTagsCache: {},
      showTranslateLang: false,
      // isOpen: typeof localStorage !== 'undefined' && localStorage.getItem('isOpen') ? Number(localStorage.getItem('isOpen')) : 2, // 1off 2on
      languageMap: {
        'en': 'English',
        'ar': 'العربي',
        'de': 'Deutsch',
        'fr': 'Français',
        'es': 'Español',
        'it': 'Italiano',
        'ru': 'Pусский',
        'th': 'ภาษาไทย',
        'nl': 'Nederlands',
        'vi': 'Tiếng Việt',
        'he': 'עברית',
        'zh-tw': '繁體中文',
        'pt-br': 'Português（Brasil）',
        'sv': 'Svenska',
        'pl': 'Polski',
        'id': 'Bahasa Indonesia',
        'tr': 'Türk',
        'pt-pt': 'Português（Portugal）',
        'ja': '日本語',
        'ko': '한국어',
        'cs-cz': 'Čeština',
        'el-gr': 'Ελληνικά',
      },
      isChecked: true,
      // isLogin: isLogin(),
      COMMENT_NUM_LIMIT_LOGIN_AND_FILTER: 200,

      noMoreComment: false,
      commentScrollLoading: false,
      firstLoad: true,
      reviewList: [],
      allReportList: [],
      allReportListTotal: 0,
    }
  },
  computed: {

    ...mapState([
      'reviews_tags',
      'language',
      'only_local_status',
      'detail',
      'is_auto_translate',

      'isLoginChange',
      'commentTranslateConfig',
      'commentTranslateCache',
      'currTranslateLang',
      'translateloading',
      'translateModel',
      'commentMemberSizeConfig',
      'currentSelectedColor',
      // 'isBatchTranslate',
      'default_lang',
      // 'isAutoTranslate',
      'languageConfigSet',
      'isSelectHide',
      'tempComment',
      'polyAttrSize',
      'only_local_status',
      'showLocalReviews',
      'markSingleLocalComment',
    ]),
    ...mapGetters([
      'reviews_abt',
      'screen_abt_tsp_config',
      'comments_overview',
      'comment_overview_local',
      'show_local_reviews',
      'trail_data',
      'comments',
      'store_product_comments',
      'comment_tags',
      'translate_language',
      'is_batch_translate',
      'language_config_set',
      'colors_info',
      'comment_filter_attr_size',
      'has_fit',
      'show_point_program',
      'fit_map',
      'review_policy_info',
    ]),
    exposeFilterData() {
      return {
        is_image: 1, //按照历史逻辑这块是一直展示的  // 0=无图片筛选曝光，1=有图片筛选曝光
        is_trailreport: this.trail_data.length ? 1 : 0, //0=无试用筛选曝光，1=有试用筛选曝光
        is_rating: 1, //0=无星级筛选曝光，1=有星级筛选曝光
        is_product: this.colorsList.length || this.sizeList.length ? 1 : 0, //0=无商品筛选曝光，1=有商品筛选曝光
        is_sort: 1, //0=无排序按钮曝光，1=有排序按钮曝光
      }
    },
    // 是否显示平台真实性文案说明
    isShowValuableReviews() {
      return this.reviews_abt?.Reviewchange?.param?.Comment_des === 'Yes'
    },
    // 是否需要展示部分评论被隐藏提示
    shouldHideReviewTipsShow() {
      const { tabType } = this

      if (tabType !== 'review' || tabType !== 'image') {
        return false
      }

      const totalCount = this.renderCommentList.length

      // 未筛选时，评论数量>1000展示折叠文案
      // 筛选后，评论数>200折叠
      const { isLogin, hasCommentFilter, COMMENT_NUM_LIMIT_LOGIN_AND_FILTER } = this
      if (isLogin && hasCommentFilter && totalCount >= COMMENT_NUM_LIMIT_LOGIN_AND_FILTER) {
        return true
      }

      if (totalCount >= COMMENT_FUZZY_LIMIT) {
        return true
      }
      return false
    },
    // isAbtCommentAntiSpiderShowToLogin() {
    //   const { isLogin, paginationPage } = this
    //   if (isLogin) return false

    //   const maxPage = Math.ceil(COMMENT_NO_LOGIN_SHOW_LIMIT / 3)
    //   // 命中abt 未登陆同时评论数据还未请求完
    //   if (paginationPage >= maxPage) {
    //     return true
    //   }
    //   return false
    // },
    // 评论是否有筛选行为
    hasCommentFilter() {
      // 触发筛选行为 指的是 用户筛选skc size 图片 星级 关键词
      const { fetchParams } = this
      return ['comment_rank', 'tag_id', 'is_picture', 'size'].some(k => !!fetchParams[k])
    },
    // 展示的评论总数
    // * 不含站外评论
    // * 不含试用报告 ！此处同bff主接口有差异，commentNumShow不包含试用报告
    AllCounts () {
      const { commentNumShow } = this.comments_overview

      return commentNumShow
    },
    // 评论总数, 并不精确，为了兼容之前的逻辑
    Counts() {
      return parseInt(this.AllCounts) + this.allReportListTotal
    },
    showRatePanel () {
      // 评分大于0 && 有评论 && 有尺码
      return this.comments_overview.comment_rank_average > 0 && this.Counts > 0
    },
    filterAttrs() {
      const commentFilterAttrSize = this.comment_filter_attr_size
      if (!commentFilterAttrSize.length) return []
      // 造一个filter data
      const filterData = [
        {
          attr_id: (commentFilterAttrSize[0] && commentFilterAttrSize[0].attr_id) || '',
          attr_name: (commentFilterAttrSize[0] && commentFilterAttrSize[0].attr_name) || '',
          attr_values: commentFilterAttrSize
        }
      ]
      return filterData
    },
    sizeList() {
      return this.filterAttrs[0]?.attr_values?.filter(size => !!size.attr_value_id) ?? []
    },
    colorsList() {
      const list = [{
        goods_id: '',
        goods_sn: '',
        attr_value: `${this.language.SHEIN_KEY_PC_14677} ${this.language.SHEIN_KEY_PC_14546}`,
        goods_color_image: `${this.PUBLIC_CDN}/she_dist/images/all-color-677f0d511f.png`,
      }]
      return list.concat(this.colors_info)
    },

    // 渲染的当前页评论列表
    renderCommentList() {
      const {
        commentListPlaceholder,
      } = this

      if(this.tabType === 'freeTrail') {
        return this.allReportList
      }

      // if(this.outStoreReviewList.length && this.Counts == 0) {
      //   return this.outStoreReviewList
      // }
      // 同pwa差异，pc要展示站外评论
      // 有筛选不展示站外评论
      const { is_picture, comment_rank, size, sort, tag_id } = this.fetchParams || {}
      const flag = is_picture || comment_rank || size || sort || tag_id 
      if(this.outStoreReviewList.length && !flag) {
        return commentListPlaceholder.concat(this.outStoreReviewList)
      }
      return commentListPlaceholder
    },
    commentListPlaceholder(){
      const allLength = this.reviewCurrSortTotal + this.buyBoxTotal
      const calculateList = []
      const commentData = [...this.reviewList]
      let reportList = [...this.allReportList]
      if(['review', 'image'].includes(this.tabType ) && (this.fetchParams.sort || this.fetchParams.tag_id)){
        // sort时 review-tab不展示试用报告
        reportList = []
      }
      // let commentData = new Array(allLength).fill({ isComment: true })
      if(commentData.length == 0) return reportList
      for (let i = 0; i < commentData.length; i++) {
        if (reportList.length === 0) {
          return calculateList.concat(commentData)
        }
        if ((calculateList.length) % 3 === 0) {
          calculateList.push(reportList.shift())
        }
        if(commentData[i]?.same_flag == 1){ // commentData[i]?.same_flag == 1
          // 遇到拼接评论时，说明主评论加载完了。如果有剩余试用报告，要先拼接所有剩余的试用报告
          return [...calculateList, ...reportList, ...commentData]
        }
        calculateList.push(commentData.shift())
        if(i == allLength - 1 && reportList.length){  
          // 如果主评论先被消费完了,且还有试用报告未消费完，则填充到后面
          return calculateList.concat(reportList)
        }
      }
      return calculateList
    },
    // allReportList: {
    //   get() {
    //     return this.trail_data
    //   },
    //   set() {
        
    //   }
    // },
    isGroupSame() {
      const { SameLabel } = this.reviews_abt
      return SameLabel?.param?.Groupsame == 'A'
    }
  },
  watch: {
    // fetchParams: {
    //   handler: function(newValue, oldValue) {
    //     console.log('fetchParams', newValue, oldValue)
    //     // 修改limit和offset不请求数据
    //     if (this.noRefresh) {
    //       this.noRefresh = false
    //       return
    //     }
    //     const fetchParamsOptCheck = this.fetchParamsOptCheck(newValue, oldValue)
    //     if (!oldValue.spu) {
    //       // this.fectMoreComment()
    //       // this.fetchMemberSizeConfig()
    //       // this.fetchCommentTranslateConfig()
    //       // document.addEventListener('click', (event) => {
    //       //   var e = event || window.event //浏览器兼容性
    //       //   var elem = e.target || e.srcElement
    //       //   if(typeof elem.className === 'string' && elem.className && elem.className.indexOf('j-translate-select') < 0 && this.translateModel && this.translateModel.comment_id) {
    //       //     this.refreshModal({
    //       //       comment_id: ''
    //       //     })
    //       //   }
    //       // })
    //     } else {
    //       if (fetchParamsOptCheck.isChangeSortOrfilter) {
    //         this.exposeDate = Date.now()
    //       }
    //       // this.refreshReview()
    //     }
    //   },
    //   deep: true
    // },
    tempComment () {
      if (this.reviewList.find(i => i.comment_id === this.tempComment.comment_id)) {
        this.reviewList = this.reviewList.map(i => {
          if (i.comment_id === this.tempComment.comment_id) {
            Object.assign(i, this.tempComment)
          }
          return i
        })
      }
    },
  },
  async created() {
    // TODO BFF - Delete - 后期pc开始中间层融合时，此处将删除
    // await this.updateBffInfo()
    // this.initConfigData()
    this.init()
  },
  mounted() {
    this.bindEventListener()
    this.initConfigData()
  },
  methods: {
    ...mapActions([
      'like_click_action',
      'fetch_batch_translate',

      'showLoginModal',
    ]),
    ...mapMutations([
      'assignState',
      'update_default_lang',
      'update_is_auto_translate',
      'update_reviews_tags',
      'update_comment_rank_average',
    ]),
    init() {
      this.update_reviews_tags(this.comment_tags || [])
      this.reviewList = this.comments
      this.allReportList = this.trail_data
      this.allReportListTotal = this.trail_data.length
      this.outStoreReviewList = this.store_product_comments

      const sortList = [
        {
          title: this.language.SHEIN_KEY_PC_15620,
          value: ''
        },
        {
          title: this.language.SHEIN_KEY_PC_14674,
          value: 'time_desc'
        },
        {
          title: this.language.SHEIN_KEY_PC_14675,
          value: 'time_asc'
        }
      ]

      sortList.splice(2, 1)

      this.filter.sort = sortList
    },
    initConfigData() {
      const languageConfigSet = this.language_config_set || []
      const defaultLang =
        localStorage.getItem('defaultLang') ||
        (languageConfigSet.includes(navigator.language) && navigator.language) ||
        (languageConfigSet.includes(gbCommonInfo.lang) && gbCommonInfo.lang) ||
        languageConfigSet[0]
      this.update_default_lang(defaultLang)
      this.isChecked = localStorage.getItem('isOpen') ? Boolean(localStorage.getItem('isOpen') === 'true') : true

      this.update_is_auto_translate(this.isChecked)
      if (this.is_batch_translate) this.fetchBatchTranslate()
    },
    handleFilterDa(extraData = {}) {  
      const { 
        comment_rank,
        goods_id,
        size,
        is_picture,
      } = this.fetchParams
      // color,size,1star,2star,3star,4star,5star,trial report,picture
      const typeArr = []
      if(comment_rank) {
        typeArr.push(comment_rank + 'star')
      }
      if(goods_id) {
        typeArr.push('color')
      }
      if(size) {
        typeArr.push('size')
      }
      if(this.tabType === 'freeTrail') {
        typeArr.push('trial report')
      }else if(is_picture) {
        typeArr.push('picture')
      }
      if(typeArr.length === 0) {
        typeArr.push('none')
      }
      daEventCenter.triggerNotice({
        daId: '1-8-5-64',
        extraData: {
          retult: 'success',
          type: typeArr.join(','),
          num: this.tabType === 'freeTrail' ? this.allReportListTotal : this.AllCounts,
          ...extraData,
        }
      })
    },
    clickFilterReport(type, val) {
      const reportTypeList = ['image', 'trailreport', 'rating', 'sort', 'color', 'size', 'comment_rank']
      if(!reportTypeList.includes(type)) return 
      if(type === 'color' || type === 'size') {
        type = 'product'
      }
      if(type === 'comment_rank') {
        type = 'rating'
      }
      const extraData = {
        type
      }
      if(type === 'rating') {
        extraData.rating_detail = val
      }
      if(type === 'sort') {
        extraData.sort_type = val ? 1 : 0
      }
      daEventCenter.triggerNotice({
        daId: '1-69-1-3',
        extraData
      })
    },
    bindEventListener() {
      typeof document !== undefined && document.addEventListener('click', (event) => {
        var e = event || window.event //浏览器兼容性
        var elem = e.target || e.srcElement
        if(typeof elem.className === 'string' && elem.className && elem.className.indexOf('j-translate-select') < 0 && this.translateModel && this.translateModel.comment_id) {
          this.refreshModal({
            comment_id: ''
          })
        }
      })
    },
    fetchParamsOptCheck(newVal, oldValue) {
      const fetchParamsOpt = {
        isChangeSortOrfilter: false,
        isAwaitFtReportData: false,
        isIgnoreCurrentChange: false
      }
      if (newVal && oldValue) {
        Object.keys(newVal).forEach(key => {
          if (key != 'page' && newVal[key] != oldValue[key]) fetchParamsOpt.isChangeSortOrfilter = true
          if (['page', 'comment_rank', 'size'].indexOf(key) > -1 && newVal[key] != oldValue[key]) fetchParamsOpt.isAwaitFtReportData = true
          if (['limit', 'offset'].indexOf(key) > -1 && newVal[key] != oldValue[key]) fetchParamsOpt.isIgnoreCurrentChange = true
        })
      }
      return fetchParamsOpt
    },

    sortReportList (mode) {
      this.allReportList = this.allReportList.sort((a, b) => {
        const aTime = new Date(a.addTime).getTime()
        const bTime = new Date(b.addTime).getTime()
        return mode === 'time_desc' ? bTime - aTime : aTime - bTime
      })
      this.reportList = this.allReportList.length ? [this.allReportList[0]] : []
    },
    getExposeId(index, id) {
      let params = []
      const exposeId = `${index}-${id}`
      Object.keys(this.fetchParams).forEach(key => {
        if (this.fetchParams[key]) params.push(this.fetchParams[key])
      })
      return `${exposeId}-${this.exposeDate}-${params.join('-')}-reviews`
    },
    async reSetFetchParams() {
      let spu = this.productRelationID
      // let result = {}
      let abtParam = []
      let localCommentAbtFlag = ''
      try {
        const { Reviewlabel, LocalReviews, PointProgram } = await abtservice.getUserAbtResult({ posKeys: 'Reviewlabel,LocalReviews,PointProgram' })
        abtParam = [ 'recsrch_sort:A' ]
        const labelAbtParam = Reviewlabel.param && Reviewlabel.param.match(/type=(.*)/)[1] || ''
        /**
         * @var LocalReviews
         * type=A | 不展示本地评论
         * type=B | 展示本地评论
         * type=C | 三期，展示本地评论，展示单条评论本地标识
         * type=D | 三期，展示本地评论，不展示单条评论本地标识，后端评论排序策略调整
         * type=E | 三期，同C，后端评论排序策略调整
         */
        const localSiteAbt = (LocalReviews?.param || 'type=A')
        localCommentAbtFlag = LOCAL_COMMENT_ABT_FLAG_MAP.get(localSiteAbt) || ''
        let markSingleLocalCommentFlag = false
        if (['type=C', 'type=E'].includes(localSiteAbt)) {
          markSingleLocalCommentFlag = true
        }
        this.showPointProgram = PointProgram?.param === 'type=A'
        // A 展示标签；B 不展示标签
        if (labelAbtParam == 'A') {
          abtParam.push('recsrch_tag:A')
        }
        abtParam = abtParam.filter(i => !!i)
        this.assignState({
          showLocalReviews: LOCAL_COMMENT_ABT_FLAG_MAP.has(localSiteAbt),
          markSingleLocalComment: markSingleLocalCommentFlag,
        })
      // eslint-disable-next-line no-empty
      } catch (error) {}

      this.fetchParams = {
        spu: spu,
        goods_id: '',
        page: 1,
        limit: 20,
        offset: 0,
        sort: '',
        size: '',
        is_picture: '',
        // rule_id: result.param || '',
        rule_id: abtParam.join('|'),
        tag_id: '',     // 评论标签的id
        // 查询本地评论请求参数
        local_site_abt_flag: localCommentAbtFlag,
      }
    },
    /**
   * 评论标签的点击
   */
    handleTagClick(id) {
      let fetchParams = {
        ...this.fetchParams,
        page: 1,    // 页码重置1
      }
      // 当前选中要取消
      fetchParams.tag_id = fetchParams.tag_id == id ? '' : id

      this.fetchParams = fetchParams
      this.noMoreComment = false
      this.fectMoreComment()
    },
    // 按顺序返回数据
    handleSize(data) {
      let info = []
      if (data['member_weight']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14642,
          value: data['member_weight']
        })
      }
      if (data['member_height']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14643,
          value: data['member_height']
        })
      }
      if (data['member_bust']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14644,
          value: data['member_bust']
        })
      }
      if (data['member_waist']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14645,
          value: data['member_waist']
        })
      }
      if (data['member_hips']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14646,
          value: data['member_hips']
        })
      }
      if (data['member_bra_size'] && this.isShowBraSize) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14626,
          value: data['member_bra_size']
        })
      }
      // report brasize 字段不一样
      if (data['member_brasize'] && this.isShowBraSize) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14626,
          value: data['member_brasize']
        })
      }
      return info
    },
    filterAttrValue(colors) {
      if(colors?.mainSaleAttribute?.[0]) {
        const color = colors.mainSaleAttribute[0]
        return { title: color.attr_value }
      }
      return { title: '' }
    },
    resetFilter() {
      this.fetchParams = {
        ...this.fetchParams,
        is_picture: '',
        goods_id: '',
        sku: '',
        comment_rank: '',
        tag_id: '',
      }
    },
    clickReview (tabType, type, val) {
      if(this.tabType === tabType) return
      this.tabType = tabType
      this.resetFilter()
      if (['review', 'image'].includes(tabType)) {
        this.clickFilter(type, val)
      } else {
        this.handleFilterDa()
      }
      this.$nextTick(() => {
        if (typeof GB_SHEIN_lazyLoadInstance != 'undefined') GB_SHEIN_lazyLoadInstance.update()
      })
      // 
      if(type === 'is_picture' && val == 1) {
        this.clickFilterReport('image')
      } else if (tabType === 'freeTrail') {
        this.clickFilterReport('trailreport')
      }
    },
    clickFilter(type, val) {
      let fetchParams = {
        ...this.fetchParams
      }
      // if (type != 'is_picture') {
      //   // 触发筛选条件且不是图片时重置试用报告数据
      //   this.reportPage = 1
      //   this.shouldFetchReportList = true
      // }
      if (type == 'sort') {
        // 试用报告排序，tabType=review不展示试用报告
        this.sortReportList(val)
        // this.shouldFetchReportList = false
      }
      if (type === 'color') {
        const { goods_id, goods_sn } = val
        fetchParams['goods_id'] = goods_id
        fetchParams['sku'] = goods_sn
      } else {
        fetchParams[type] = val
      }
      fetchParams.page = 1
      type != 'sort' && (fetchParams.tag_id = '') // 标签重置
      this.fetchParams = fetchParams
      this.clickFilterType = type
      this.fetchFtReportList()
      this.noMoreComment = false
      this.fectMoreComment('filter')
      this.clickFilterReport(type, val)
    },

    async fectMoreComment(type = '') {
      if(this.commentScrollLoading || this.noMoreComment || this.tabType === 'freeTrail') return
      this.commentScrollLoading = true
      this.fetchReviewList().then(async (commentList) => {

        const outStoreReviewList = commentList.store_product_comments || []
        if (outStoreReviewList.length > 0) {
          this.outStoreReviewList = outStoreReviewList.map((comment, index) => ({
            ...(comment || {}),
            isOutStoreReview: true,
            isFirstOne: index === 0,
            comment_id: comment.store_comment_id,
          }))
        }
        // 上报是否有站外评论
        daEventCenter.triggerNotice({
          daId: '1-8-5-55',
          extraData: {
            OutReview: this.outStoreReviewList?.length > 0 ? 1 : 0
          }
        })

        if (
          this.Counts === 0
        ) {
          if (this.outStoreReviewList.length !== 0) {
            // 仅有站外评论，通知父级展示
            this.$emit('only-store-comment')
          } else {
            // 无任何评论，则隐藏评论块
            this.$emit('hide-comment')
          }
        }

        if (commentList && commentList.commentInfo && commentList.commentInfo.length > 0) {
          ////
          if (this.fetchParams.page == 1) {
            this.reviewList = commentList.commentInfo
          } else {
            this.reviewList = this.reviewList.concat(commentList.commentInfo)
          }
        } else {
          this.noMoreComment = true
        }
        if(type === 'filter') {
          this.reviewList = commentList.commentInfo
        }
        // 只有第一页有返回数据
        if(this.fetchParams.page == 1) {
          this.update_reviews_tags(commentList.comment_tags || [])
          // ssr时可能会返回全站评分跟本地一样，所以要在此更新全站评分
          this.update_comment_rank_average(commentList?.comments_overview?.comment_rank_average || 0)
        }
        this.noRefresh = true
        this.fetchParams.page++
        // this.Counts = this.total + this.allReportList.length
        this.assignState({
          allReportNum: this.allReportList.length,
          storeRate: commentList.shopRank || 0,
          commentInfoNum: commentList.commentInfoTotal,
          buyBoxTotalNum: commentList.buyBoxTotal
        })
        this.$nextTick(function() {
          if (typeof GB_SHEIN_lazyLoadInstance != 'undefined') {
            GB_SHEIN_lazyLoadInstance.update()
          }
        })
        // 埋点上报
        if (this.clickFilterType) {
          if(['comment_rank', 'color', 'size', 'is_picture'].includes(this.clickFilterType)) {
            this.handleFilterDa({
              num: commentList?.comments_overview?.commentNumShow
            })
          }

          this.clickFilterType = ''
        }

        this.exposeDate = Date.now()
        // this.ImageCounts = commentList.pictureTotal
        // if (commentList.picCommentFuzzy) {
        //   // this.imageFuzzy = true
        //   this.imageCountsStr = commentList.picCommentNumStr
        // }
        // 评论关键词标签
        if (!this.reviewsTagsCache[this.reviewsTagsCacheKey]) {
          this.reviewsTagsCache[this.reviewsTagsCacheKey] = commentList.spuTag || []
        }
        if (this.is_batch_translate) this.fetchBatchTranslate()

        // 在此认为评论数据已加载过
        this.assignState({ reviewBaseDataReady: true })
      })
    },
    // 评论列表
    fetchReviewList() {
      return new Promise( (resolve) => {
        // const not_need_img = Boolean(this.fetchParams.is_picture) ? 0 : 1
        this.commentScrollLoading = true
        const { 
          reviews_abt,
          only_local_status
        } = this
        const {
          goods_spu,
          sku,
          store_code,
          cat_id,
          isLowestPriceProductOfBuyBox,
          mainProductSameGroupId,
          goods_id,
        } = this.detail
        const bffParams = {
          ...this.fetchParams,
          goods_spu,
          local_site_query_flag: only_local_status ? '1' : '',
          local_site_abt_flag: only_local_status ? '1' : '',
          sku: this.fetchParams.sku || sku,
          store_code,
          tag_rule_id: reviews_abt?.Reviewlabel?.param || '',
          store_comment_flag: 1,
          cat_id,
          // TODO 保留款参数,等主接口,推荐接口融合
          isLowestPriceProductOfBuyBox,
          mainProductSameGroupId,
          mainGoodsId: goods_id,
        }
        schttp({
          url: '/product/get_goods_review_detail',
          params: bffParams,
          useBffApi: true,
        }).then(data => {
          data = commentAdapter.abcCommentAdapter(data, { page: bffParams.page })
          const { code = null } = data || {}
          if (code !== null && Number(code) === 0 && data.info) {
            this.noMoreComment = false
            resolve(data.info)
          } else {
            this.noMoreComment = true
          }
          this.commentScrollLoading = false
        }).catch(err => {
          this.commentScrollLoading = false
          resolve(err)
        })
      })
    },
    async fetchFtReportList() {
      const {
        goods_spu,
        // sku,
      } = this.detail
      const { size, comment_rank, sort } = this.fetchParams || {}
      const bffParams = {
        sku: this.fetchParams.sku,
        score: comment_rank || '',
        attr_value_en: size,
        spu: goods_spu
      }
      if (sort && sort !== 'default') {
        bffParams.sort = sort
      }
      let data = await schttp({
        url: '/user/trial/get_list_with_goods_detail',
        params: bffParams,
        useBffApi: true,
      }) 
      data = commentAdapter.freeTrailAdapter({
        info: {
          free_trial_list: data?.info.free_trial_list ?? []
        }
      }) || []

      data = makeFreeList(data.info.free_trial_list) || []
      this.allReportList = data

      if(this.tabType === 'freeTrail') { // 仅当试用报告tab时上报， 其余都在fetchReviewList中上报
        this.handleFilterDa({
          num: this.allReportList.length
        })
      }
    },
    async fetchBatchTranslate () {
      this.reviewList = await this.fetch_batch_translate(this.reviewList)
    },

    reviewFeedback(item, type) {
      if (item.reportId) {
        return this.handleFtReportFeedback(item, type)
      }
      if(!isLogin()){
        return this.showLoginModal({ type: 'review_feedback', originRef: this, callback: () => {
          if(typeof window !== 'undefined') {
            // 登录成功之后，去掉快速注册
            window.gbRegisterModule && window.gbRegisterModule.rawHide()
            if (window.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window.gbCommonInfo?.WEB_CLIENT === 'shein') {
              this.model.show = false
              this.reviewFeedback(item, type)
            }
          }
        } })
      }
      if (this.feedbacking) return
      this.feedbacking = true
      this.like_click_action({
        ...item,
        sku: this.fetchParams.sku,
      }).then(json => {
        this.feedbacking = false
        if (json.code == 0) {
          let activeIndex, i
          for (i = 0; i < this.reviewList.length; i++) {
            if (this.reviewList[i].comment_id == item.comment_id) {
              activeIndex = i
              break
            }
          }
          let isLike = Number(item.current_member_zan) == 1 ? 0 : 1
          if (typeof activeIndex != 'undefined') {
            this.reviewList[activeIndex].like_num = isLike ? Number(this.reviewList[activeIndex].like_num) + 1 : Number(this.reviewList[activeIndex].like_num) - 1
            this.reviewList[activeIndex].feedbacked = isLike ? true : false
            this.reviewList[activeIndex].current_member_zan = Number(item.current_member_zan) == 1 ? 0 : 1
          }
          if (this.model.comments[item.comment_id]) {
            this.model.comments[item.comment_id].like_num = isLike ? Number(this.model.comments[item.comment_id].like_num) + 1 : Number(this.model.comments[item.comment_id].like_num) - 1
            this.model.comments[item.comment_id].feedbacked = isLike ? true : false
            this.model.comments[item.comment_id].current_member_zan = isLike ? 1 : 0
          }

          if (type == 'model') {
            // daEventCenter.triggerNotice({
            //   daId: '1-8-5-19',
            //   extraData: {}
            // })
          } else {
            daEventCenter.triggerNotice({
              daId: '1-8-5-4',
              extraData: {
                is_cancel: isLike ? 0 : 1
              }
            })
          }
        } else {
          if (json.code == 200728 || json.code == 300206 || json.code == '00101110') {
            return this.showLoginModal({ type: 'review_feedback', originRef: this, callback: () => {
              if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
                this.model.show = false
                this.reviewFeedback(item, type)
              }
            } })
          }
        }
      })
    },
    handleFtReportFeedback(item) {
      if (!isLogin()) {
        return this.showLoginModal({ type: 'report_feedback', originRef: this, callback: () => {
          if(typeof window !== 'undefined' ) {
            // 登录成功之后，去掉快速注册
            window.gbRegisterModule && window.gbRegisterModule.rawHide()
            if (window.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window.gbCommonInfo?.WEB_CLIENT === 'shein') {
              this.model.show = false
              this.handleFtReportFeedback(item)
            }
          }
        } })
      }
      if (this.feedbacking) return
      this.feedbacking = true
      this.like_click_action({
        ...item,
        sku: this.fetchParams.sku,
      }).then(json => {
        this.feedbacking = false
        if (json.code == 0) {
          let activeIndex, i
          for (i = 0; i < this.allReportList.length; i++) {
            if (this.allReportList[i].reportId == item.reportId) {
              activeIndex = i
              break
            }
          }
          let isLike = Number(item.current_member_zan) == 1 ? 0 : 1
          if (typeof activeIndex != 'undefined') {
            this.allReportList[activeIndex].like_num = isLike ? Number(this.allReportList[activeIndex].like_num) + 1 : Number(this.allReportList[activeIndex].like_num) - 1
            this.allReportList[activeIndex].current_member_zan = isLike ? true : false
          }
          if (this.model.comments[item.comment_id]) {
            this.model.comments[item.comment_id].like_num = isLike ? Number(this.model.comments[item.comment_id].like_num) + 1 : Number(this.model.comments[item.comment_id].like_num) - 1
            this.model.comments[item.comment_id].feedbacked = isLike ? true : false
            this.model.comments[item.comment_id].current_member_zan = isLike ? 1 : 0
          }

          daEventCenter.triggerNotice({
            daId: '1-8-5-33',
            extraData: {
              is_cancle: item.current_member_zan ? 1 : 0
            }
          })
          // 试用报告点赞之后清缓存
          // this.fetchFtReportList(1)
        } else if (json.code == 200728 || json.code == 300206 || json.code === '00101110') {
          this.showLoginModal({ type: 'report_feedback', originRef: this, callback: () => {
            if(typeof window !== 'undefined' ) {
              // 登录成功之后，去掉快速注册
              window.gbRegisterModule && window.gbRegisterModule.rawHide()
              if (window.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window.gbCommonInfo?.WEB_CLIENT === 'shein') {
                this.model.show = false
                this.handleFtReportFeedback(item)
              }
            }
          } })
          // return this.showLoginModal({ type: 'report_feedback', originRef: this })
        }
      })
    },
    clickCommentImage({ comment, index }) {
      if (comment.reportId) { // 试用
        this.handleOpenImageModal(comment.comment_id, index)
      } else {
        this.model.fetchParams = {
          ...this.fetchParams
        }
        this.model.clickMode = 'review'
        this.model.commentId = comment.comment_id
        this.model.index = index
        daEventCenter.triggerNotice({
          daId: '1-69-1-8'
        })
      }
    },
    handleOpenImageModal (commentId, index) {
      daEventCenter.triggerNotice({
        daId: '1-69-1-8',
        extraData: {
          freetrial_id: commentId.replace('rp', '')
        }
      })
      // 点击试用报告的图片，评论弹窗先展示试用报告，再展示评论，评论从第一页开始
      this.model.clickMode = 'freeTrail'
      this.model.commentId = commentId
      // this.model.freeTrailIndex = this.allReportList.findIndex(_ => _.reportId == reportId)
      this.model.index = index
      this.model.fetchParams = {
        ...this.fetchParams
      }
    },
    refreshModelImages(image) {
      this.model.commentImages = image
    },
    refreshModelComment(comment) {
      this.model.comments = comment
    },
    refreshReviewFeedback() {
      location.reload()
    },
    translateClose(comment_id) {
      this.assignState({
        commentTranslateCache: {
          ...this.commentTranslateCache,
          [comment_id]: {
            translateCon: '',
            translateConTags: [],
            translateConSelectTags: [],
            translateLang: this.currTranslateLang
          }
        }
      })
    },
    refreshLoading(translateloading) {
      this.assignState({
        'translateloading': translateloading
      })
    },
    refreshTranslate(comment_id, info, curr_lang) {
      const { dest_text, dest_content_tag, dest_select_tag } = info || {}
      this.assignState({
        commentTranslateCache: {
          ...this.commentTranslateCache,
          [comment_id]: {
            translateCon: dest_text || '',
            translateConTags: dest_content_tag || [],
            translateConSelectTags: dest_select_tag || [],
            translateLang: curr_lang || this.currTranslateLang
          }
        }
      })
    },
    refreshTranslateLang(lang) {
      this.assignState({
        'currTranslateLang': lang
      })
    },
    refreshModal(params) {
      this.assignState({
        translateModel: {
          ...this.translateModel,
          ...params
        }
      })
    },
    handleChange ({ checked = false } = {}) {
      localStorage.setItem('isOpen', checked)
      this.update_is_auto_translate(checked)

      daEventCenter.triggerNotice({
        daId: '1-8-5-23',
        extraData: {
          translate_language: this.currTranslateLang || '',
          type: checked ? 'batch_on' : 'batch_off'
        }
      })
    },
    changeLanguage (lang) {
      this.showTranslateLang = false
      this.update_default_lang(lang)
      localStorage.setItem('defaultLang', lang)
      daEventCenter.triggerNotice({
        daId: '1-8-5-25',
        extraData: {
          translate_language: lang,
          type: 'batch'
        }
      })
      // daEventCenter.triggerNotice({
      //   daId: '1-8-5-39',
      //   extraData: {
      //     language: lang
      //   }
      // })
      this.fetchBatchTranslate()
    },
    changeLocalComment() {
      const { only_local_status } = this
      const fetchParams = {
        ...this.fetchParams,
        local_site_query_flag: only_local_status ? '1' : '',
        is_local: true,
        tag_id: ''
      }
      fetchParams.page = 1
      this.noMoreComment = false
      this.fetchParams = fetchParams
      this.fectMoreComment()
    },
    updateTempComment(params) {
      this.assignState({
        'tempComment': params
      })
    },
    commentAnalysis(comment, index) {
      return {
        'data-comment-id': comment.comment_id,
        'data-comment-page': this.paginationPage,
        'data-index': Number(index) + 1,
        'data-out-store-review': comment.isOutStoreReview ? '1' : '',
        'data-same-flag': comment.same_flag
      }
    },
    getAnalysisData(comment, index) {
      const { show_point_program, translate_language } = this
      const { comment_id, isOutStoreReview, same_flag, content_tag, comment_image } = comment
      const commentIdStr = String(comment_id || '')
      const isReport = commentIdStr.indexOf('rp') > -1
      const reviewTypeStr = isOutStoreReview ? 1 : isReport ? 2 : 0
      const contentTag = (() => {
        if (!Array.isArray(content_tag)) {
          return []
        }
        return content_tag.filter(tag => tag && tag.name && tag.content)
      })()

      return {
        id: '1-69-1-4',
        data: {
          review_id: comment_id, // 评论 ID
          position: index + 1, // 位置序号，从 1 递增
          page: this.fetchParams.page, // 页码序号，从 1 递增
          review_type: reviewTypeStr, //评论类型，0=站内评论，1=站外评论，2=试用报告
          review_source: same_flag || 0, //评论来源，0=主商品评论，1=同款评论（包括保留款 + buybox最低价款）
          is_translate: translate_language.length > 0 ? 1 : 0, // 是否有单条翻译，0=无翻译按钮展示，1=有翻译按钮展示
          is_point_rating: +show_point_program, // 是否有 Point Program，0=无Point Program展示，1=有Point Program展示
          image_num: comment_image?.length ?? 0, //评论中图片数量，0=评论无图，有图的情况下有几张图片报几张图片
          is_size_info_expand: 0, //（pc无icon）是否有尺码信息展开，0=没有尺码信息展开，1=有尺码信息展开
          is_guide_label: contentTag.length ? 1 : 0, //是否有 引导标签，0=没有引导标签，1=有引导标签
        }
      }
    },
    setCommentData(comment) {
      // 兼容评论信息 
      const { is_batch_translate } = this
      const showTranslateText = is_batch_translate && !comment.showOrigin && comment.translateText
      comment.contentText = showTranslateText ? comment.translateText : comment.content

      return comment
    },
    likeClickHandle(item) {
      const { comment_id } = item
      const isFree = !!~comment_id.indexOf('rp')
      if (isFree) {
        this.handleFtReportFeedback(item)
      } else {
        this.reviewFeedback(item)
      }
    },
    // 点击登陆显示更多评论
    handleShowCommentToLogin() {
      if (isLogin()) return
      this.showLoginModal({ type: 'reviews_tologin', originRef: this })
    },
  },
}
</script>

<style lang="less" scoped>
.common-reviews {
  &__valuable-reviews {
    margin-bottom: 16px;
  }
  &__select {
    position: sticky;
    top: 46px;
    left: 0;
    background: #fff;
    z-index: 99;
  }
}
.comment-popup {
  .common-reviews {
    &__select {
      display: flex;
      height: 66px;
      align-items: center;
      // justify-content: space-between;
      position: relative;
      border: none;
    }
    &__select-tab {
      height: 100%;
      .tab{
        padding: 23px 0;
      }
    }
    &__select-wrap {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
<style lang="less">
.batch-translate {
    height: 55px;
    .sui-checkbox {
        margin-top: 20px;
        margin-right: 0;
        float: right;
    }
    &__text {
        margin-top: 19px;
        font-size: 14px;
        float: right;
        color: #222;
    }
    &__text-blue {
        font-weight: 800;
        cursor: pointer;
        position: relative;
    }
    ul {
        position: absolute;
        top: 25px;
        .right(0);
        padding: 5px 0;
        min-width: 170px;
        background-color: #fff;
        font-size: 14px;
        border: 1px solid #f6f6f6;
        max-height: 192px;
        overflow-y: auto;
        z-index: @zindex-hack;
        box-shadow: 0 2px 5px rgba(0,0,0,.08);
        color: #222;
        /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
        -ms-overflow-style:none;
        /*火狐下隐藏滚动条*/
        overflow:-moz-scrollbars-none;
        scrollbar-width: none;
        /*Chrome下隐藏滚动条，溢出可以透明滚动*/
        &::-webkit-scrollbar{
            width: 0;
        }
    }
    li {
        padding: 0 40px 0 15px; /*rtl:ignore*/
        line-height: 36px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        &:hover {
            background-color: rgba(0,0,0,.08);
        }
        .translate-button {
            background: transparent;
            height: unset;
            border: none;
        }
        .translate-lang {
            font-weight: 800;
        }
    }
}
.common-reviews{
  &.sticky-under-gallery{
    margin-top: 0px;
    // overflow: hidden;
    .sui-pagination{
      flex-wrap: wrap;
    }
    .review-filter_panel-colors{
      flex-wrap: wrap;
    }
    .common-reviews__tab {
      padding: 50px 0 16px 0;
      font-family: 'Arial';
    }
    .common-reviews__tag{
      padding: 25px 0px 0;
    }
    .common-reviews__select {
      display: flex;
      align-items: center;
    }
    .common-reviews__select-wrap{
      // float: left;
      display: flex;
      // flex-wrap: wrap;
    }
    .common-reviews__select-tab{
      width: 100%;
      // border-bottom: 1px solid #E5E5E5;
      .tab{
        padding: 25px 0;
      }
    }
    .common-reviews__tag-item{
      background: rgba(255, 248, 235, 1);
      border: none;
      height: 27px;
      line-height: 27px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: #222;
      &.active {
        color: #A86104;

        &:before {
          display: none;
        }
      }
    }
    .common-reviews__select-item{
      margin-left: 0;
      margin-right: 15px;
      font-size: 14px;
      color: #222;
      &:last-child{
        margin-right: 0px;
      }
    }
    .common-reviews__averate {
      flex-direction: column;
      min-height: auto;
    }
    
    .multi-rate{
      display: none;
      &.new-middle__multi-rate{
        display: flex;
      }
    }
    .common-reviews__averate-item{
      padding: 12px 18px;
      .name {
        display: none;
      }
    }
    .j-expose__common-reviews__list-item{
      &:last-child{
        .rate-translate-select{
          ul{
            max-height: 110px;
          }
        }
      }
    }
  }
  &__select{
    .clearfix();
    border-bottom: 1px solid #e5e5e5;
  }
  &__select-tab{
    float: left;
    font-size: 14px;
    color: #666;
    font-weight: 700;
    /* rw:begin */
    font-family: 'Adieu';
    .tab{
      position: relative;
      padding: 25px 0;
      display: inline-block;
      cursor: pointer;
      /* rw:begin */
        font-weight: normal;
        color: @sui_color_gray_dark1;
      & + .tab{
        .margin-l(25px);
      }
    }
    .active{
      color: #222;
      /* rw:begin */
        font-weight: bold;
        color: @sui_color_main;
      &:after{
        position: absolute;
        .left(0);
        .right(0);
        bottom: -1px;
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #222/*rw:#FF696E*/;
      }
    }
  }
  &__select-wrap{
    float: right;
    // padding: 11px 0;
    font-size: 14px;
    color: #222;
  }
  &__select-item{
    display: inline-block;
    white-space: nowrap;
    & + .common-reviews__select-item{
      .margin-l(25px);
    }
    .img{
      display: inline-block;
    }
    img{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      vertical-align: middle;
    }
  }
  &__select-box{
    position: relative;
    padding: 5px 0;
    .margin-l(6px);
    display: inline-block;
    .txt-l();
    &:hover {
      // .icon-jiantou-copy {
      //   transform: rotate(180deg);
      //   display: inline-block;
      // }
      .common-reviews__select-box-input{
        border: 1px solid #222;
        .img{
          &:after{
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border: 1px solid fade(#000, 8%);
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 5px;
          }
        }
      }
      .common-reviews__select-box-list{
        display: block;
      }
    }
  }
  &__select-box-input{
    display: inline-flex;
    align-items: center;
    padding: 0 15px;
    min-width: 80px;
    height: 34px;
    line-height: 32px;
    border: 1px solid #e5e5e5;
    text-align: center;
    cursor: pointer;
    .img{
      position: relative;
      display: inline-block;
    }
    img{
      vertical-align: sub;
      .margin-r(0);
    }
    span{
      font-weight: bold;
    }
    i{
      font-size: 14px;
    }
  }
  &__select-box-list{
    position: absolute;
    top: 44px;
    .left(0);
    padding: 5px 0;
    min-width: 140px;
    background-color: #fff;
    font-size: 12px;
    border: 1px solid #f6f6f6;
    max-height: 192px;
    overflow-y: auto;
    z-index: @zindex-hack;
    box-shadow: 0 2px 5px rgba(0,0,0,.08);
    display: none;
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style:none;
    /*火狐下隐藏滚动条*/
    overflow:-moz-scrollbars-none;
    scrollbar-width: none;
    /*Chrome下隐藏滚动条，溢出可以透明滚动*/
    &::-webkit-scrollbar{
      width: 0;
    }
    li{
      padding: 0 40px 0 15px /*rtl:ignore*/;
      line-height: 34px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &:hover{
        background-color: fade(#000, 8%);
        .img{
          &:after{
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border: 1px solid fade(#000, 8%);
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 8px;
          }
        }
      }
    }
    .img{
      position: relative;
      .margin-r(5px);
      display: inline-block;
    }
  }
  &__null {
    margin: 66px 0 0;
    .null-icon {
      color: @sui_color_main;
    }
  }
  &__fuzzy-tips {
    padding-top: 24px;
    padding-bottom: 8px;

    & > p {
      color: @sui_color_gray_light1;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
    }
  }
  &__tologin {
    @height: 50px;
    height: @height;
    margin-top: -@height - 1px;
    background-color: #fff;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      width: 100%;
      height: 48px;
      position: absolute;
      bottom: @height;
      background: linear-gradient(0, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    }
    // background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    &_text {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom:16px;
      color: #222;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      .tologin-icon {
        margin-left: 4px;
      }
      // .icon {
      //   margin-left: 4px;
      // }
    }
  }
  &__oms{
    position: relative;
    padding: 15px 0 0;
  }
  &__reportip{
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 22px;
    color: #666;
    .reportip-icon {
      margin: 0 5px 0 0;
    }
  }

  .common-reviews__paginationBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.with-tip {
      justify-content: space-between;
    }

    .outside-comment-tip {
      font-size: 12px;
      color: @sui_color_gray_dark2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__pageViewAll {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(---sui_color_black, #000);
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    .icon {
      margin-left: 4px;
    }
  }
}

</style>
