<template>
  <modal-vue
    class="customrreviews-image-modal"
    :show="reviewsShow"
    :vertical="false"
    @cancel="close()"
    @ok="close()"
  >
    <template #title>
      <div></div>
    </template>
    <div class="customerreviews-details">
      <div
        class="customerreviews-details__image-wrap"
        :style="main_img_style"
      >
        <div
          class="customerreviews-details__image-swiper"
          :style="swiper_height"
        >
          <div
            ref="customerreviews-details__swiper"
            class="s-swiper-container"
          > 
            <div
              ref="customerreviews-details__prev"
              class="swiper-button-prev"
              :class="{ 'swiper-button-disabled': bigPrevDisable }"
              @click="mainSlideLeft"
            ></div>
            <swiper-container 
              ref="swiper"
              class="s-swiper-wrapper"
              init="false"
            >
              <swiper-slide
                v-for="(item) in commentImages"
                :key="item.comment_id"
                class="s-swiper-slide customerreviews-details__image-slide-item"
              >
                <img
                  :src="getImgUrl(item.member_image_original || item.imgOriginal)"
                  :loading="!GB_cssRight ? 'lazy' : ''"
                />
                <div class="swiper-lazy-preloader"></div>
              </swiper-slide>
            </swiper-container>
            <div
              ref="customerreviews-details__next"
              class="swiper-button-next"
              :class="{ 'swiper-button-disabled': bigNextDisable }"
              @click="mainSlideRight"
            ></div>
          </div>
        </div>
        <div
          class="customerreviews-details__image-thumbs"
          :style="thumb_img_style"
        >
          <div
            ref="customerreviews-details__thumbs-prev"
            class="swiper-button-prev"
            :class="{ 'swiper-button-disabled': smallPrevDisable }"
            @click="thumbSlideLeft"
          >
            <Icon
              name="sui_icon_more_left_18px"
              size="24"
              color="#000"
              class="button-icon"
            />
          </div>
          <div
            ref="customerreviews-details__thumbs-swiper"
            class="s-swiper-container"
          >
            <swiper-container 
              ref="swiper2"
              class="s-swiper-wrapper"
              init="false"
            >
              <swiper-slide
                v-for="(item, idx) in commentImages"
                :key="item.comment_id"
                class="s-swiper-slide customerreviews-details__image-thumbs-item"
                :class="{
                  'customerreviews-details__image-thumbs-item_active':
                    idx == bigIndex,
                }"
                :data-index="idx"
                :data-comment-id="item.comment_id"
              >
                <div
                  class="customerreviews-details__image-thumbs-image"
                  @click="thumbsImageTap(item.comment_id, idx)"
                >
                  <img
                    :src="getImgUrl(item.member_image_small || item.imgSmall)"
                    :loading="!GB_cssRight ? 'lazy' : ''"
                  />
                  <div class="swiper-lazy-preloader"></div>
                </div>
              </swiper-slide>
            </swiper-container>
          </div>
          <div
            ref="customerreviews-details__thumbs-next"
            class="swiper-button-next"
            :class="{ 'swiper-button-disabled': smallNextDisable }"
            @click="thumbSlideRight"
          >
            <Icon
              name="sui_icon_more_right_18px"
              size="24"
              color="#000"
              class="button-icon"
            />
          </div>
        </div>
      </div>
      <div
        v-if="commentInfo[currCommentId]"
        class="customerreviews-details__des-wrap"
      >
        <div
          ref="customerreviews-details__des-box"
          class="customerreviews-details__des-box"
          :style="rightContentStyle()"
        >
          <div class="customerreviews-details__des-head">
            <div class="rate">
              <strong class="user-name">{{
                commentInfo[currCommentId].user_name
              }}</strong>
              <CommonRate
                :rank-num="commentInfo[currCommentId].comment_rank"
                :size="'small'"
              />
            </div>
            <div class="date">
              {{ formatTime(commentInfo[currCommentId]) }}
            </div>
          </div>
          <div class="customerreviews-details__des-detail">
            {{
              commentInfo[currCommentId].translateText &&
                !commentInfo[currCommentId].showOrigin
                ? htmlDecode({ text: commentInfo[currCommentId].translateText })
                : htmlDecode({ text: commentInfo[currCommentId].content })
            }}
          </div>
          <ul
            v-if="tagList.length"
            class="customerreviews-details__comment-tag-box"
          >
            <li
              v-for="(tag, tagIndex) in tagList"
              :key="tagIndex"
            >
              <span class="tag-name">{{ tag.name }}</span>: <span class="tag-content">{{ tag.content }}</span>
            </li>
          </ul>
          <CommentTranslate
            v-if="commentInfo[currCommentId].translate_language.length > 0"
            :comment-translate-cache="commentTranslateCache"
            :comment-translate-config="commentTranslateConfig"
            :curr-translate-lang="currTranslateLang"
            :translateloading="translateloading"
            :translate-model="translateModel"
            :comment="commentInfo[currCommentId]"
            :spu="commentInfo[currCommentId].spu"
            :language="language"
            :is-batch-translate="isBatchTranslate"
            :is-auto-translate="isAutoTranslate"
            :temp-comment="tempComment"
            from="modal"
            @close="translateClose"
            @refresh-loading="refreshLoading"
            @refresh-translate-cache="refreshTranslate"
            @refresh-translate-lang="refreshTranslateLang"
            @refresh-modal="refreshModal"
            @update-temp-comment="updateTempComment"
          />
          <div class="customerreviews-details__color-size">
            <div
              v-if="computedAttrList.length"
              class="customerreviews-details__color-size-attr"
            >
              <span
                v-for="(attr) in computedAttrList(
                  commentInfo[currCommentId]
                )"
                :key="attr.key"
              >
                <strong>{{ attr.key }}:</strong>
                {{ attr.value }}
              </span>
            </div>
            
            <div
              v-if="showGoodsId"
              class="customerreviews-details__color-size-goodsId"
              @click="addCart(commentInfo[currCommentId].goods_id)"
            >
              <strong>{{ language.SHEIN_KEY_PC_24086 }}</strong>
              <Icon
                name="sui_icon_more_right_14px_1"
                size="14px"
              />
            </div>
          </div>
          <template v-if="showUserSize">
            <div
              v-if="
                commentInfo[currCommentId].member_size_new &&
                  commentInfo[currCommentId].member_size_new.length
              "
              class="customerreviews-details__des-size"
            >
              <div
                v-if="commentInfo[currCommentId].member_size_new &&
                  commentInfo[currCommentId].member_size_new.length"
                class="customerreviews-details__des-size-title"
              >
                {{ htmlDecode({ text: language.SHEIN_KEY_PC_17107 }) }}
              </div>
              <ul class="customerreviews-details__des-size-list">
                <!-- <li
                  v-if="
                    hasFit &&
                      commentInfo[currCommentId].member_cat_size &&
                      commentInfo[currCommentId].member_cat_size
                        .member_overall_fit
                  "
                >
                  <span>{{ language.SHEIN_KEY_PC_14647 }}</span>
                  {{
                    fitMap[
                      commentInfo[currCommentId].member_cat_size
                        .member_overall_fit
                    ]
                  }}
                </li> -->
                <template
                  v-for="(size, key) in commentInfo[currCommentId].member_size_new"
                  :key="key"
                >
                  <li>
                    <span>{{ size.language_name }}:</span>
                    {{ size.size_value }}
                  </li>
                </template>
              </ul>
            </div>
          </template>

          <div class="bottom">
            <div
              v-if="showLike"
              ref="customerreviews-details__thumbup"
              class="customerreviews-details__thumbup"
              :class="{
                'customerreviews-details__haslike':
                  Number(commentInfo[currCommentId].like_num) > 0,
              }"
              @click="handelFeedback"
            >
              <!-- <div
                v-if="showPop"
                class="customerreviews-details__like-pop"
              >
                {{ language.SHEIN_KEY_PC_17971 }}.
              </div>
              <span
                v-if="showPop"
                class="customerreviews-details__like-icon"
              ></span> -->
              <!-- thumbup-icon -->
              <template v-if="commentInfo[currCommentId].current_member_zan == 1">
                <Icon
                  name="sui_icon_like_completed_24px"
                  size="24px"
                  :color="IS_RW ? '#FC4070' : '#000'"
                  class="thumbup-icon"
                />
              </template>
              <template v-else>
                <Icon
                  name="sui_icon_like_24px"
                  size="24px"
                  color="#000"
                  class="thumbup-icon"
                />
              </template>

              <p class="customerreviews-details__likenum">
                {{ language.SHEIN_KEY_PC_17999 }}
                <span
                  v-if="commentInfo[currCommentId].like_num > 0"
                >({{ commentInfo[currCommentId].like_num }})</span>
              </p>
            </div>
            <!-- Report -->
            <div
              v-if="showReport"
              class="customerreviews-details__report"
            >
              <CommentReport v-bind="reportAttrs" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div></div>
    </template>
  </modal-vue>
</template>

<script>
import CommonRate from 'public/src/pages/goods_detail_v2/components/CommonRate.vue'
import CommentTranslate from './CommentTranslate'
import { debounce } from 'lodash'
import { daEventCenter } from 'public/src/services/eventCenter/index.js'

import {
  htmlDecode,
  transformImg,
} from '@shein/common-function'
import CommentReport from 'public/src/pages/goods_detail_v2/components/Comment/CommentReport/index.vue'
import schttp from 'public/src/services/schttp'
import { mapState, mapActions } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { register } from 'swiper/element'
import commentAdapter from 'public/src/pages/goods_detail_v2/utils/adapters/comment.js'
import { calOldGoodsAttr } from 'public/src/pages/goods_detail_reviews/store/utils.js'

// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()
let QuickViewPopover = null

daEventCenter.addSubscriber({ modulecode: '1-8-5' })

let pageLimit = 20
// let isLoadPrev = true // 是否加载上一页的数据
let BI
let clientHeight
let clientWidth
let imgBI
let mianWidth
let isPortrait
let minIconWidth
let slideLimit
// let exposeCommentTranslate = []

const { GB_cssRight, IS_RW } = gbCommonInfo

export default {
  name: 'CustomerReviewsImageModal',
  components: {
    CommonRate,
    CommentReport,
    CommentTranslate,
    Icon
  },
  props: {
    value: [Boolean],
    commentId: [Number, String],
    fetchParams: [Object],
    index: [Number, String],
    freeTrailIndex: [Number, String],
    freeTrailSubIndex: [Number, String],
    reportList: {
      type: Array,
      default: () => [],
    },
    clickMode: {
      type: String,
      default: 'review',
    },
    watermarkConfig: {
      type: Object,
      default: () => {},
    },
    commentImages: {
      type: Array,
      default: () => [],
    },
    commentInfo: {
      type: Object,
      default: () => {},
    },
    allCommentInfo: {
      type: Array,
      default: () => [],
    },
    fitMap: {
      type: Object,
      default: () => {},
    },
    language: {
      type: Object,
      default: () => {},
    },
    handleSize: {
      type: Function,
      default() {
        return new Function()
      },
    },
    commentMemberSizeConfig: {
      type: Array,
      default: () => [],
    },
    showUserSize: {
      type: Boolean,
      default: true,
    },
    showLike: {
      type: Boolean,
      default: true,
    },
    showGoodsId: {
      type: Boolean,
      default: false,
    },
    showReport: {
      type: Boolean,
      default: true,
    },
    skc: {
      type: String,
      default: '',
    },
    location: [String, Number], // 1/2/3/4（1评论2试用3社区4店铺）
    shopId: String,
    tempComment: Object,
    isAutoTranslate: Boolean,
    isBatchTranslate: Boolean,
    defaultLang: String,
    productRelationID: String,
    commentTranslateCache: Object,
    commentTranslateConfig: Object,
    currTranslateLang: String,
    translateloading: Array,
    translateModel: Object,
    isShowBraSize: Boolean,
    hasFit: Number,
    isGroupSame: Boolean,
    mainGoodsId: {
      type: String,
      default: '',
    },
    buyBoxSameId: {
      type: String,
      default: '',
    },
    isMainGoodsLowestPrice: {
      type: Number,
      default: 0,
    },
    screenAbtConfig: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      GB_cssRight,
      IS_RW,
      currCommentId: null,
      reviewsShow: false,
      mainSwiper: null,
      thumbsSwiper: null,
      currPageNoData: false,
      prevPageNoData: false,
      currPage: 1,
      prevPage: 1,
      bigIndex: -1,
      smallIndex: -1,
      slidePage: 1,
      smallNextDisable: false,
      smallPrevDisable: false,
      bigNextDisable: false,
      bigPrevDisable: false,
      main_img_style: {},
      thumb_img_style: {},
      swiper_height: {},
      commentInfoTotal: 0,
      showPop: false,
      reviewBigSwiper: null,
      reviewSmallSwiper: null,
      exposeIndexArr: new Set(), // 已曝光的评论索引
    }
  },
  computed: {
    ...mapState([
      'only_local_status',
      'detail',
    ]),
    shopParams() {
      return {
        shop_id: this.shopId,
        spu: `shop_${this.shopId}`,
      }
    },
    // 当前评论
    currentComment() {
      const { commentInfo, currCommentId } = this
      if (!commentInfo || !currCommentId) {
        return null
      }
      return commentInfo[currCommentId] || null
    },
    reportAttrs() {
      // 举报来源：location：1/2/3/4（1评论2试用3社区4店铺）
      const { currCommentId, location, skc } = this
      const commentId = currCommentId + ''
      const isFree = !!~commentId.indexOf('rp')
      return {
        commentId: isFree ? commentId.replace('rp', '') : currCommentId,
        analysis: { activity_from: 'page_picture_show', location },
        isFree,
        skc,
        closeModal: this.close,
        reportMemberId: this.currentComment?.member_id,
      }
    },
    // 当前要展示的评论引导标签列表
    tagList() {
      const { currentComment } = this
      if (!currentComment) {
        return []
      }
      // 展示翻译内容
      const tagFilter = (tag) => tag && tag.name && tag.content
      const { showOrigin, translateText, translateTags, content_tag } =
        currentComment
      const showTranslate = showOrigin && translateText
      if (showTranslate) {
        if (Array.isArray(translateTags)) {
          return translateTags.filter(tagFilter)
        }
        return []
      }

      // 展示原始内容
      if (Array.isArray(content_tag)) {
        return content_tag.filter(tagFilter)
      }
      return []
    },
  },
  watch: {
    bigIndex(newVal) {
      this.exposeIndexArr.add(newVal)
    },
    value(newVal) {
      this.reviewsShow = newVal
      this.reviewsShow && this.initLikeTips()
      if (+this.currCommentId === +this.commentId) return
      this.currCommentId = this.commentId
    },
    commentImages(newval, oldval) {
      if (newval.length !== oldval.length) {
        this.$nextTick(() => {
          if(!this.reviewBigSwiper) return
          this.bigIndex = this.reviewBigSwiper.realIndex
          this.smallIndex = this.bigIndex 
          this.reviewBigSwiper.update(true)
          this.reviewSmallSwiper.update(true)
          this.slideToActive()
        })
      }
    },
    commentId(val) {
      this.currCommentId = val
    },
    fetchParams(newVal, oldVal) {
      if (this.isChange(newVal, oldVal)) {
        this.resetState()
        this.loadData(() => {
          this.$emit('update:value', true)
          daEventCenter.triggerNotice({ daId: '1-8-5-12' })
        })
      } else {
        this.$emit('update:value', true)
        this.bigIndex = this.getActiveIndex()
        this.smallIndex = this.bigIndex
        this.$nextTick(() => {
          this.slideToActive()
        })
        daEventCenter.triggerNotice({ daId: '1-8-5-12' })
      }
    },
    tempComment() {
      if (this.commentInfo[this.tempComment.comment_id]) {
        this.commentInfo[this.tempComment.comment_id]['showOrigin'] =
          this.tempComment.showOrigin
      }
    },
  },
  mounted() {
    this.bindEvent()
  },
  methods: {
    ...mapActions([
      'like_click_action',
      'fetch_batch_translate',
      'update_is_auto_translate',

      'showLoginModal',
    ]),
    // ...mapMutations(['showLoginModal']),
    ...mapActions(['autoGetCouponAtAddBag']),
    calOldGoodsAttr(comment) {
      return calOldGoodsAttr(comment, this.language)
    },
    // 试用 & 评论做兼容
    computedAttrList(item) {
      const list = []
      if(item.sku_info_list?.length ) {
        item.sku_info_list.forEach((attr) => {
          list.push({
            key: attr.attribute_name_multi,
            value: attr.attribute_value_multi,
          })
        })
      } else if(item.sku_sale_attr?.length) {
        item.sku_sale_attr.forEach((attr) => {
          list.push({
            key: attr.attr_name,
            value: attr.attr_value_name,
          })
        })
      }
      return list
    },
    mianImgStyle() {
      let width = Math.floor(clientHeight - 50 - 60 - 80 * imgBI)
      return {
        width: (width + 80 > clientWidth ? clientWidth / 2 : width) + 'px',
      }
    },
    swiperHeight() {
      return {
        height: clientHeight - 50 - 60 - 80 + 'px',
      }
    },
    thumbImgStyle() {
      let width = Math.floor(clientHeight - 50 - 60 - 80 * imgBI)
      return {
        width: (width + 80 > clientWidth ? clientWidth / 2 : width) + 'px',
        margin: `${20 * BI}px 0 0 0`,
        padding: `0 ${minIconWidth}px`,
      }
    },
    computeStyle() {
      BI = document.body.clientWidth / 1920
      clientHeight = document.body.clientHeight
      clientWidth = document.body.clientWidth
      imgBI = 670 / 796
      mianWidth = Math.floor(clientHeight - 50 - 60 - 80 * imgBI)
      isPortrait = mianWidth + 80 > clientWidth
      minIconWidth = 40 * BI < 25 ? 25 : 40 * BI
      slideLimit = Math.floor(
        (isPortrait ? clientWidth / 2 : mianWidth - minIconWidth) / 50
      )
      let width = Math.floor(clientHeight - 50 - 60 - 80 * imgBI) + 420
      this['main_img_style'] = this.mianImgStyle()
      this['thumb_img_style'] = this.thumbImgStyle()
      this['swiper_height'] = this.swiperHeight()
      setTimeout(() => {
        const modalContent = this.$el.querySelector('.modal-content')
        if (!modalContent) return
        const newWidth = (width > clientWidth ? clientWidth - 20 : width) + 'px'
        modalContent.style.width = newWidth

      }, 0)
    },
    bindEvent() {
      this.computeStyle()
      window.addEventListener && window.addEventListener('orientationchange', () => {
        setTimeout(() => {
          this.computeStyle()
          this.$nextTick(() => {
            this.reviewBigSwiper = null
            this.reviewSmallSwiper = null
            this.swiperInit()
            this.slideToActive()
          })
        }, 1000)
      })
    },

    rightContentStyle() {
      return {
        height: clientHeight - 60 - 100 + 'px',
      }
    },
    isChange(newVal, oldVal) {
      let value = false
      for (var i in newVal) {
        if (newVal[i] != oldVal[i]) {
          value = true
          break
        }
      }
      return value
    },
    resetState() {
      this.$emit('refresh-model-images', [])
      this.nextPageNoData = false
      this.prevPageNoData = false
      this.currPage = 1
      this.prevPage = 1
      this.slidePage = 1
    },
    close() {
      this.exposeIndexArr.delete(-1)
      daEventCenter.triggerNotice({ daId: '1-69-1-9', extraData: {
        num: this.exposeIndexArr.size
      } })
      this.bigIndex = -1
      this.exposeIndexArr.clear()
      this.$emit('update:value', false)
      // daEventCenter.triggerNotice({ daId: '1-8-5-9' })
    },
    mainSlideRight() {
      let _this = this
      let swiper = _this.reviewBigSwiper
      let isFetchData
      let slide_index
      if (_this.fetching || !swiper) return
      // 先将small swiper 滚动到对应的位置
      slide_index = swiper.realIndex + 1
      _this.bigIndex = slide_index
      _this.smallIndex = slide_index
      _this.toggleComment(
        _this.commentImages[slide_index].comment_id,
        slide_index
      )
      _this.slideToActive(500)
      // 再判断是否需要加载下一页
      _this.slidePage = Math.ceil(
        _this.reviewSmallSwiper.realIndex / slideLimit
      )
      isFetchData =
        _this.commentImages.length - _this.slidePage * slideLimit <
        2 * slideLimit + 1
      if (isFetchData) _this.nextLoadData()
      // daEventCenter.triggerNotice({ daId: '1-8-5-10' })
    },
    mainSlideLeft() {
      let _this = this
      let swiper = _this.reviewBigSwiper
      let isFetchData
      let slide_index
      if (_this.fetching || !swiper) return
      // 先将small swiper 滚动到对应的位置
      slide_index = swiper.realIndex - 1
      _this.bigIndex = slide_index
      _this.smallIndex = slide_index
      _this.toggleComment(
        _this.commentImages[slide_index].comment_id,
        slide_index
      )
      _this.slideToActive(500)
      // 再判断是否需要加载下一页
      _this.slidePage = Math.ceil(
        _this.reviewSmallSwiper.realIndex / slideLimit
      )
      isFetchData = _this.slidePage * slideLimit < slideLimit + 1
      if (isFetchData && _this.prevPage != 1) _this.prevLoadData()
      // daEventCenter.triggerNotice({ daId: '1-8-5-11' })
    },
    thumbSlideRight(e) {
      e.stopPropagation()
      e.preventDefault()
      // 双击箭头用户名会神奇的选中，所以这些需要清除选中
      window?.getSelection().removeAllRanges()
      let _this = this
      let swiper = _this.reviewSmallSwiper
      let isFetchData
      if (_this.fetching || !swiper) return
      _this.slidePage = Math.ceil(
        _this.reviewSmallSwiper.realIndex / slideLimit
      )
      _this.slidePage = _this.slidePage + 1
      isFetchData =
        _this.commentImages.length - _this.slidePage * slideLimit <
        2 * slideLimit + 1
      if (isFetchData) _this.nextLoadData()
      swiper.slideNext()
      // daEventCenter.triggerNotice({ daId: '1-8-5-7' })
    },
    thumbSlideLeft() {
      let _this = this
      let swiper = _this.reviewSmallSwiper
      if (_this.fetching || !swiper) return
      _this.slidePage = Math.ceil(
        _this.reviewSmallSwiper.realIndex / slideLimit
      )
      _this.slidePage = _this.slidePage - 1
      let isFetchData = _this.slidePage * slideLimit < slideLimit + 1
      if (isFetchData && _this.prevPage != 1) _this.prevLoadData()
      swiper.slidePrev()
      // daEventCenter.triggerNotice({ daId: '1-8-5-8' })
    },
    async loadData(cb) {
      let page = 1
      if (this.clickMode != 'freeTrail') {
        page = await this.getRequestPage()
      }
      let fetchParams = {
        ...this.fetchParams,
        page: page,
      }
      this.currPage = page
      let fetchArr = []
      fetchArr.push(
        this.promiseFetch({ params: fetchParams, type: 'next', isFirst: true })
      ) //当前页及下一页
      if (page > 1) {
        fetchParams.page = page - 1
        fetchArr.push(this.promiseFetch({ params: fetchParams, type: 'prev' })) //上一页
      }
      this.handleLoadData(fetchArr, cb)
    },
    handleLoadData(fetchArr, cb) {
      Promise.all(fetchArr).then((data) => {
        let [currArr, prevArr] = data
        let imageArr = []
        if (prevArr) imageArr = imageArr.concat(prevArr)
        if (currArr) imageArr = imageArr.concat(currArr)
        this.$emit('refresh-model-images', this.commentImages.concat(imageArr))
        if (currArr.length == 0) {
          this.nextPageNoData = true
          this.fetching = false
          return
        } else if (currArr.length < pageLimit) {
          this.nextPageNoData = true
        }
        if (typeof cb === 'function') cb()
        this.$nextTick(() => {
          this.swiperInit()
          this.bigIndex = this.getActiveIndex()
          this.smallIndex = this.bigIndex
          this.slideToActive()
          this.slidePage = Math.ceil(
            this.reviewSmallSwiper.realIndex / slideLimit
          )
        })
      })
    },
    // 加载上一页
    prevLoadData(cb) {
      let params = {
        ...this.fetchParams,
        page: this.prevPage,
      }
      if (this.prevPageNoData) return
      this.fetching = true
      this.promiseFetch({
        params: params,
        type: 'prev',
        limit: slideLimit,
      }).then((data) => {
        if (data.length == 0) {
          this.prevPageNoData = true
          this.fetching = false
          return
        } else if (data.length < slideLimit + 1) {
          this.prevPageNoData = true
        }
        this.$emit('refresh-model-images', this.commentImages.concat(data))
        if (typeof cb === 'function') cb()
        this.nextTick()
      })
    },
    // 加载下一页
    nextLoadData(cb) {
      let params = {
        ...this.fetchParams,
        page: this.currPage,
      }
      if (this.nextPageNoData) return
      this.fetching = true
      this.promiseFetch({
        params: params,
        type: 'next',
        limit: slideLimit,
      }).then((data) => {
        if (data.length == 0) {
          this.nextPageNoData = true
          this.fetching = false
          return
        } else if (data.length < slideLimit + 1) {
          this.nextPageNoData = true
        }
        this.$emit('refresh-model-images', this.commentImages.concat(data))
        if (typeof cb === 'function') cb()
        this.nextTick()
      })
    },
    // 切换评论
    toggleComment(comment_id, index) {
      this.currCommentId = comment_id
      this.bigIndex = index
      this.smallIndex = index
    },
    // 单个获取数据
    promiseFetch({ params, type, isFirst } = {}) {
      let noData = type == 'prev' ? 'prevPageNoData' : 'currPageNoData'
      return new Promise((resolve) => {
        if (type == 'prev') {
          this.prevPage = params.page
        } else {
          // 首次加载从当前页开始
          isFirst ? params.page : params.page++
          this.currPage = params.page
        }

        params.limit = pageLimit
        // params.is_picture = 1
        if (this.shopId) {
          params = {
            ...params,
            ...this.shopParams,
          }
        }
        delete params.offset
        // const firstResultIndex = (params.page - 1) * params.limit
        this.fetchData(params).then((info) => {
          let loadData = []
          // info = this.indexCheck(firstResultIndex, info)
          info = this.minxinReport(info)
          info.forEach((item) => {
            if (!this.commentInfo[item.comment_id])
              this.commentInfo[item.comment_id] = item
            if (item.comment_image && item.comment_image.length) {
              item.comment_image.forEach((image) => {
                image.comment_id = item.comment_id
                loadData.push(image)
              })
            }
          })
          if (this.isBatchTranslate) this.fetchBatchTranslate()
          if (info.length == 0 || (type == 'prev' && params.page <= 1))
            this[noData] = true
          resolve(loadData)
        })
      })
    },
    minxinReport(commentData = []) {
      if (commentData.length > 0) {
        const allList = []
        const { reportList } = this
        const _reportList = JSON.parse(JSON.stringify(reportList))
        for (let i = 0; i < commentData.length; i++) {
          if (_reportList.length === 0) {
            return allList.concat(commentData)
          }
          if (allList.length % 3 === 0) {
            allList.push(_reportList.shift())
          }
          allList.push(commentData.shift())
        }
        if (_reportList.length) {
          return allList.concat(_reportList)
        }
        return allList
      }

      return commentData
    },
    indexCheck(firstResultIndex, infoList) {
      // firstResultIndex： 原始索引
      if (firstResultIndex === 0 && this.clickMode === 'freeTrail') {
        return this.reportList.concat(infoList)
      }
      // 最后一个膨胀索引
      const crossover = 3 * this.reportList.length - 2
      const isLastpage = this.commentInfoTotal - firstResultIndex <= pageLimit
      let resultList = []
      let formulaVal = 0
      // 判断图片评论总数与试用报告总数的索引关系，计算索引临界值
      // 计算原索引膨胀后的索引值 0，1，2，3，4，5，6，7
      // 0 -> 0, 1 -> 2, 2 -> 3, 3 -> 5, 4 -> 6, 5 -> 8, 6 -> 9, 7 -> 11
      if (Math.ceil(firstResultIndex / 2) + firstResultIndex > crossover) {
        // 不需要插入试用报告
        resultList = resultList.concat(infoList)
      } else {
        while (infoList.length) {
          if (Math.ceil(firstResultIndex / 2) + firstResultIndex <= crossover) {
            resultList.push(infoList.shift())
            formulaVal =
              (Math.ceil(firstResultIndex / 2) + firstResultIndex) / 3
            // 满足索引插入公式，插入对应索引的试用报告
            if (~~formulaVal === formulaVal) {
              resultList.push(this.reportList[formulaVal])
            }
            firstResultIndex += 1
          } else {
            // 试用报告已全部插入现有的评论，拼接剩余评论数返回
            return resultList.concat(infoList)
          }
        }
      }

      // 如果是最后一页并且还有多余的试用报告，将试用报告拼接在最后
      if (
        isLastpage &&
        Math.ceil((this.commentInfoTotal - 1) / 2) +
          (this.commentInfoTotal - 1) <=
          crossover
      ) {
        resultList = resultList.concat(this.reportList.slice(formulaVal + 1))
      }
      return resultList
    },
    fetchData() {
      return new Promise((resolve, reject) => {
        // 第一次打开直接取评论页数据
        if(this.allCommentInfo.length && this.currPage == 1) {
          return resolve(this.allCommentInfo)
        }
        const { 
          screenAbtConfig,
          only_local_status
        } = this
        const {
          goods_spu,
          sku,
          store_code,
          cat_id,
          isLowestPriceProductOfBuyBox,
          mainProductSameGroupId,
        } = this.detail
        const bffParams = {
          ...this.fetchParams,
          page: this.currPage,
          is_picture: 1,
          goods_spu,
          local_site_query_flag: only_local_status ? '1' : '',
          sku: this.fetchParams.sku || sku,
          store_code,
          tag_rule_id: screenAbtConfig?.Reviewlabel?.param || '',
          store_comment_flag: 1,
          cat_id,
          // TODO 保留款参数,等主接口,推荐接口融合
          isLowestPriceProductOfBuyBox,
          mainProductSameGroupId,
        }
        schttp({
          url: '/product/get_goods_review_detail',
          params: bffParams,
          useBffApi: true,
        })
        // schttp({
        //   method: 'GET',
        //   url: '/api/comment/abcCommentImages/query',
        //   params: {
        //     ...params,
        //     spu: goods_spu,
        //     goods_id: mainGoodsId,
        //     same_query_flag: this.isGroupSame ? 1 : 0,
        //     buy_box_low_price_flag: this.isMainGoodsLowestPrice,
        //     buy_box_same_id: this.buyBoxSameId,
        //   },
        // })
          .then((json) => {
            json = commentAdapter.abcCommentAdapter(json, { page: bffParams.page })
            if (json.info && json.info.commentInfo) {
              this.commentInfoTotal = json.info.commentInfoTotal
              resolve(json.info.commentInfo)
            } else {
              resolve([])
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 批量翻译
    async fetchBatchTranslate() {
      const comment_infos = await this.fetch_batch_translate(Object.keys.values(this.commentInfo))
      comment_infos.forEach((item) => {
        if (this.commentInfo[item.comment_id]) {
          this.commentInfo[item.comment_id] = comment_infos[item.comment_id]
        }
      })
      // let comment_infos = []
      // Object.keys(this.commentInfo).length &&
      //   Object.keys(this.commentInfo).forEach((i) => {
      //     if (
      //       !this.commentInfo[i].translateText ||
      //       this.commentInfo[i].current_lang !== this.defaultLang
      //     ) {
      //       comment_infos.push({
      //         comment_id: this.commentInfo[i].comment_id,
      //         src_language: this.commentInfo[i].language_flag,
      //       })
      //     }
      //   })
      // const _this = this
      // if (comment_infos.length) {
      //   schttp({
      //     method: 'POST',
      //     url: '/api/comment/batchTranslate/query',
      //     data: {
      //       spu: this.productRelationID,
      //       dest_language: this.defaultLang,
      //       comment_infos: JSON.stringify(
      //         comment_infos.length > 20
      //           ? comment_infos.slice(0, 20)
      //           : comment_infos
      //       ),
      //     },
      //   })
      //     .then((data) => {
      //       if (data.code == 0 && data.info?.comment_infos?.length) {
      //         let translateComments = data.info.comment_infos
      //         Object.keys(_this.commentInfo).forEach((i) => {
      //           const hitTransComment = translateComments.find(
      //             (j) => j.comment_id === _this.commentInfo[i].comment_id
      //           )
      //           const translateText =
      //             hitTransComment?.dest_text ||
      //             (_this.defaultLang === i.language_flag && i.content) ||
      //             ''
      //           const translateTags = hitTransComment?.dest_content_tag || []
      //           const translateSelectTags =
      //             hitTransComment?.dest_select_tag || []

      //           let obj = {
      //             ..._this.commentInfo[i],
      //             current_lang: _this.defaultLang,
      //           }
      //           if (obj.translateText === undefined || translateText) {
      //             obj.translateText = translateText
      //             obj.translateTags = translateTags
      //             obj.translateSelectTags = translateSelectTags
      //             obj.showOrigin =
      //               _this.tempComment.comment_id === i
      //                 ? _this.tempComment.showOrigin
      //                 : typeof i.showOrigin === undefined
      //                   ? i.showOrigin
      //                   : !_this.isAutoTranslate
      //           }
      //           _this.commentInfo[i] = obj
      //         })
      //       }
      //       if (comment_infos.length > 3) _this.fetchBatchTranslate()
      //     })
      //     .catch(() => {
      //       //
      //     })
      // }
    },
    getActiveIndex() {
      let index, i
      for (i = 0; i < this.commentImages.length; i++) {
        if (
          this.commentImages[i] &&
          this.commentImages[i].comment_id === this.commentId
        ) {
          index = i + this.index
          break
        }
      }
      return index
    },
    getRequestPage() {
      return new Promise((resolve, reject) => {
        let params = {
          ...this.fetchParams,
          commend_id: this.commentId,
          // is_picture: 1,
          same_query_flag: this.isGroupSame ? 1 : 0,
          buy_box_low_price_flag: this.isMainGoodsLowestPrice,
          buy_box_same_id: this.buyBoxSameId,
        }
        if (this.shopId) {
          params = {
            ...params,
            ...this.shopParams,
          }
        }
        schttp({
          method: 'GET',
          url: `/api/comment/imageCommentOffsetByAbc/get`,
          params,
        })
          .then((json) => {
            let page = 1
            if (json.info && json.info.comment_id_offset) {
              page =
                Math.ceil((json.info.comment_id_offset + 1) / pageLimit) || 1
              resolve(page)
            } else {
              resolve(page)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    swiperInit() {
      let _this = this
      const swiperEl = this.$refs.swiper
      if(!this.reviewBigSwiper && swiperEl){
        const swiperParams = {
          slidesPerView: 1,
          lazyLoading: true,
          lazyLoadingInPrevNext: true,
          onlyExternal: true,
          spaceBetween: 1,
          lazyLoadingInPrevNextAmount: 1,
          on: {
            init: function (swiper) {
              let index = swiper.realIndex
              if (swiper.isEnd) _this.bigNextDisable = true
              if (index == 0) _this.bigPrevDisable = true
            },
            slideChangeTransitionEnd() {},
          }
        }
        Object.assign(swiperEl, swiperParams)
        swiperEl.initialize()
        this.reviewBigSwiper = swiperEl.swiper
      }

      const swiperEl2 = this.$refs.swiper2
      if (!this.reviewSmallSwiper && swiperEl2) {
        const swiperParams = {
          // prevButton: '.j-review-thumbnail-left',
          // nextButton: '.j-review-thumbnail-right',
          spaceBetween: 4,
          slidesPerView: slideLimit,
          slidesPerGroup: slideLimit,
          lazyLoading: true,
          onlyExternal: true,
          on: {
            init: function (swiper) {
              let index = Math.floor(swiper.realIndex / slideLimit)
              if (swiper.isEnd) _this.smallNextDisable = true
              if (index == 0) _this.smallPrevDisable = true
            },
            slideChangeTransitionEnd: function (swiper) {
              if (swiper.isEnd) {
                _this.smallNextDisable = true
              } else {
                _this.smallNextDisable = false
              }
              if (swiper.isBeginning) {
                _this.smallPrevDisable = true
              } else {
                _this.smallPrevDisable = false
              }
            },
          }
        }
        Object.assign(swiperEl2, swiperParams)
        swiperEl2.initialize()
        this.reviewSmallSwiper = swiperEl2.swiper
      }
    },
    thumbsImageTap(comment_id, index) {
      if (this.currCommentId == comment_id && this.bigIndex == index) return
      this.toggleComment(comment_id, index)
      this.slideToActive()
    },
    // 切换到指定位置 过渡时间
    slideToActive(timing) {
      let time = timing || 0
      if (this.reviewBigSwiper) {
        this.reviewBigSwiper.slideTo(this.bigIndex, time, false)
        if (this.reviewBigSwiper.isEnd) {
          this.bigNextDisable = true
        } else {
          this.bigNextDisable = false
        }
        if (this.bigIndex == 0 || this.reviewBigSwiper.isBeginning) {
          this.bigPrevDisable = true
        } else {
          this.bigPrevDisable = false
        }
      }
      if (this.reviewSmallSwiper) {
        this.reviewSmallSwiper.slideTo(this.smallIndex, time, false)
        // let index = Math.floor(this.reviewSmallSwiper.realIndex / slideLimit)
        if (this.reviewSmallSwiper.isEnd) {
          this.smallNextDisable = true
        } else {
          this.smallNextDisable = false
        }
        if (this.reviewSmallSwiper.isBeginning) {
          this.smallPrevDisable = true
        } else {
          this.smallPrevDisable = false
        }
      }
    },
    formatTime(comment = {}) {
      const { add_time, add_time_str } = comment
      const { Reviewchange } = this.screenAbtConfig || {}
      if(Reviewchange?.param?.Datedisplay === 'New' && add_time_str) {
        return add_time_str
      }
      let date = new Date(add_time * 1000)
      let mLables = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      return (
        date.getDate() +
        ' ' +
        mLables[date.getMonth()] +
        ',' +
        date.getFullYear()
      )
    },
    hasInfo(member_info, isObject) {
      const field = isObject ? 'member_size' : 'member_cat_size'
      let hasOverallFit =
        this.commentInfo[this.currCommentId][field] &&
        this.commentInfo[this.currCommentId][field].member_overall_fit != ''
      return (
        (this.hasFit && hasOverallFit) ||
        (isObject
          ? Object.keys(member_info).filter((key) => member_info[key])
          : member_info
        ).length > 0
      )
    },
    nextTick() {
      this.$nextTick(() => {
        this.bigIndex = this.reviewBigSwiper.realIndex
        this.smallIndex = this.slidePage * slideLimit
        this.reviewBigSwiper.update(true)
        this.reviewSmallSwiper.update(true)
        this.slideToActive()
        this.fetching = false
      })
    },
    handelFeedback() {
      let comment = this.commentInfo[this.currCommentId]
      this.$emit('feedback', comment, 'model')
    },
    translateClose(comment_id) {
      this.$emit('close', comment_id)
    },
    refreshLoading(translateloading) {
      this.$emit('refresh-loading', translateloading)
    },
    refreshTranslate(comment_id, info, curr_lang) {
      this.$emit('refresh-translate-cache', comment_id, info, curr_lang)
    },
    refreshTranslateLang(lang) {
      this.$emit('refresh-translate-lang', lang)
    },
    refreshModal(params) {
      this.$emit('refresh-modal', params)
    },
    handleMemberSizeKey(item) {
      let [size = null] = this.commentMemberSizeConfig
        .slice(0)
        .filter((size) => Number(item.rule_id) === Number(size.rule_id))
      return (size && size.language_name) || ''
    },
    handleMemberSizeValue(item) {
      let ruleInfoVal = ''
      const [size = null] = this.commentMemberSizeConfig
        .slice(0)
        .filter((size) => Number(item.rule_id) === Number(size.rule_id))
      const [option = null] =
        (size &&
          size.option_list &&
          size.option_list.filter(
            (option) => option.option_id == item.option_id
          )) ||
        []
      if (option) {
        if (item.rule_type == '1') {
          ruleInfoVal = `${option && option.language_name}`
        } else if (item.rule_type == '2') {
          const second_unit_val = option.transform_rate * item.option_value
          ruleInfoVal = `${item.option_value} ${
            option.default_unit
          } / ${second_unit_val.toFixed(
            Math.min(option.second_accuracy, 99)
          )} ${option.second_unit}`
        }
      }
      return ruleInfoVal
    },
    // 检测 属性值是否为空
    checkSize(item) {
      const [size = null] = this.commentMemberSizeConfig
        .slice(0)
        .filter((size) => Number(item.rule_id) === Number(size.rule_id))
      const [option = null] =
        (size &&
          size.option_list &&
          size.option_list.filter(
            (option) => option.option_id == item.option_id
          )) ||
        []
      if (item?.rule_type == '1') {
        return !!option?.language_name
      }
      if (item?.rule_type == '2') {
        return !!item?.option_value
      }
      return false
    },
    initLikeTips() {
      const isLike = localStorage.getItem('REVIEW_LIKE_TIPS')
      if (isLike) {
        this.showPop = false
      } else {
        this.scrollToShow()
      }
    },
    scrollToShow() {
      let self = this
      let end = false
      let cb = debounce(() => {
        const target = self.$refs['customerreviews-details__thumbup']
        const viewHeight =
          window.innerHeight || document.documentElement.clientHeight
        if (self.showPop || !target || end) return
        const { top, bottom } = target.getBoundingClientRect()
        if (top >= 0 && bottom <= viewHeight) {
          self.showPop = true
          end = true
          setTimeout(() => {
            self.closePop()
            self.$refs['customerreviews-details__des-box'].removeEventListener(
              'scroll',
              cb
            )
          }, 5000)
        }
      }, 200)
      self.$nextTick(() => {
        cb()
      })
      self.$refs['customerreviews-details__des-box']?.addEventListener(
        'scroll',
        cb
      )
    },
    updateTempComment(params) {
      this.$emit('updateTempComment', params)
    },
    closePop() {
      this.showPop = false
      localStorage.setItem('REVIEW_LIKE_TIPS', 1)
    },
    addCart(id) {
      let self = this
      const mainGoodsId = this.mainGoodsId
      const quickViewData = {
        goodsId: id,
        mainGoodsId,
        config: {
          showBestDealLabel: true,
          showFollowBeltByOrigin: true,
        },
        callback: {
          handleOpenLogin: (addToWish) => {
            QuickViewPopover && QuickViewPopover?.hide?.()
            this.close()
            mainGoodsId && this.showLoginModal({  // 非商详页引用的, 没有vuex，此处不执行
              type: 'qv-add-wish',
              originRef: {
                addToWish
              }
            })
          },
          handleDetailResponse: ({ detail }) => {
            this.$emit('addCart', { detail, index: this.index })
          },
          handleAddToCartDone(res){
            window.cart_module && window.cart_module.recart(res)
            // 非商详页引用的, 没有vuex，此处不执行
            mainGoodsId && self.autoGetCouponAtAddBag({ needToast: res?.code == 0, isMainGoods: mainGoodsId == id })
          },
        },
      }

      if (QuickViewPopover) {
        QuickViewPopover.show(quickViewData)
        return
      }

      import('public/src/pages/common/quickView').then(_ => {
        QuickViewPopover = _.default
        QuickViewPopover.show(quickViewData)
      })
    },
    getImgUrl (rawUrl) {
      let result = this.transformImg({ img: rawUrl })
      if (this.watermarkConfig) {
        const { showCommentWatermark = false } = this.watermarkConfig || {}
        if (showCommentWatermark && result &&  result?.indexOf('_wk_shein') === -1 && result?.indexOf('images3') > -1) {
          // 获取后缀
          const suffix = result?.split('.')?.pop() || ''
          result = suffix ? result.replace(`.${suffix}`, `_wk_shein.${suffix}`) : result
        }
      }
      return result
    },
    htmlDecode,
    transformImg,
  },
  emits: [
    'update:value',
    'refresh-model-images',
    'feedback',
    'close',
    'refresh-loading',
    'refresh-translate-cache',
    'refresh-translate-lang',
    'refresh-modal',
    'updateTempComment',
    'addCart',
  ],
}
</script>

<style lang="less">
.customrreviews-image-modal {
  .s-swiper-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
  }
  .she-mask {
    background: fade(#000, 80%);
  }
  .c-modal {
    .she-mask {
      background: fade(#000, 80%);
    }
    .modal-dialog {
      width: 1050px;
    }
    .modal-content {
      padding: 40px;
    }
    .modal-header {
      margin-top: -10px;
    }
    .she-close {
      color: #222;
    }
  }
}
.customerreviews-details {
  display: table;
  &__image-wrap {
    width: 670px;
  }
  &__image-swiper {
    padding: 10px;
    height: 796px;
    overflow: hidden;
    background-color: #f6f6f6;
    .s-swiper-container {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 24px;
      height: 24px;
      line-height: 24px;
      margin-top: 0;
      i {
        color: #fff;
        font-size: 24px;
      }
    }
    .swiper-button-prev {
      height: 100%;
      width: 50%;
      position: absolute;
      top: 0;
      .left(0);
      cursor: url('/she_dist/images/favicon-20200109034635876.ico'), auto;
    }
    .swiper-button-next {
      height: 100%;
      width: 50%;
      position: absolute;
      top: 0;
      .right(0);
      cursor: url('/she_dist/images/favicon-20200109034635523.ico'), auto;
    }
  }
  &__image-slide-item {
    text-align: center;
    line-height: 378px;
    position: relative;
    .swiper-lazy-preloader {
      /* sh:begin */
      height: 100%;
      background: url('/she_dist/images/bg-grey-ba96515e9b.png') no-repeat;
      background-size: 100%;
      /* rw:begin */
      height: 100%;
      background: url('/she_dist/rw_images/bg-grey-2fbbfd606a.png') no-repeat;
      background-size: 100%;
    }
    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__image-thumbs {
    position: relative;
    margin-top: 10px;
    padding: 0 20px;
    .swiper-button-prev,
    .swiper-button-next {
      width: 24px;
      height: 24px;
      line-height: 24px;
      margin-top: -12px;
    }
    .swiper-button-prev {
      position: absolute;
      .left(2px);
      &.swiper-button-disabled {
        opacity: 0.08;
      }
    }
    .swiper-button-next {
      position: absolute;
      .right(2px);
      &.swiper-button-disabled {
        opacity: 0.08;
      }
    }
    .button-icon {
      transform: rotate(0deg) /* rtl:rotate(180deg) */;
    }
  }
  &__image-thumbs-item {
    position: relative;
    .margin-r(2px);
    width: 50px;
    height: 50px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    &.s-swiper-slide {
      height: 50px;
    }
    .swiper-lazy-preloader {
      /* sh:begin */
      height: 100%;
      background: url('/she_dist/images/bg-grey-ba96515e9b.png') no-repeat;
      background-size: 100%;
      background-position: 50% 50%;
      /* rw:begin */
      height: 100%;
      background: url('/she_dist/rw_images/bg-grey-2fbbfd606a.png') no-repeat;
      background-size: 100%;
      background-position: 50% 50%;
    }
    img {
      width: 100%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
    &_active {
      border: 1px solid #000;
    }
  }
  &__image-thumbs-image {
    border: 1px solid #e5e5e5;
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  &__des-detail {
    margin-bottom: 10px;
    color: #222;
    font-size: 14px;
    line-height: 17px;
    word-break: break-word;
  }
  &__comment-tag-box {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
    color: @sui_color_gray_dark2;

    li {
      margin-top: 8px;
    }

    .tag-content {
      color: #222;
      word-break: break-all;
    }
  }
  &__des-wrap {
    .padding-l(40px);
    display: table-cell;
    vertical-align: top;
    color: #767676;
    width: 100%;
  }
  &__des-box {
    overflow-y: auto;
  }
  &__des-head {
    margin-bottom: 10px;
    position: relative;
    .rate {
      color: #222;
      white-space: nowrap;
      .user-name {
        font-size: 14px;
      }
      .common-rate {
        .margin-l(16px);
        display: inline-block;
      }
    }
    .date {
      position: absolute;
      .right(0);
      top: 0;
    }
  }
  &__color-size {
    display: inline-flex;
    justify-content: space-around;
    span {
      font-size: 14px;
      display: inline-block;
      .margin-r(15px);
      margin-bottom: 25px;
    }
  }
  &__color-size-attr {
    margin-bottom: 25px;
    span {
      margin-bottom: 0;
    }
  }
  &__color-size-goodsId {
    display: inline-flex; 
    cursor: pointer;
    white-space: nowrap;
  }
  &__des-size-title {
    margin-bottom: 10px;
    color: #222;
    font-size: 14px;
    font-weight: bold;
  }
  &__des-size-list {
    font-size: 14px;
    li {
      margin-bottom: 10px;
      max-width: 160px;
    }
    span {
      color: #222;
    }
  }
  &__like-pop {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    height: 34px;
    line-height: 34px;
    padding: 0px 12px;
    color: #fff;
    top: -48px;
    .left(-100px);
    white-space: nowrap;
  }
  &__like-icon {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    top: -14px;
    position: absolute;
    .left(54px);
  }
  &__thumbup {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    color: #767676;
    .thumbup-icon {
      cursor: pointer;
      margin-right: 9px;
      color: #222;
    }
  }
  &__thumbwrap {
    position: relative;
    color: #222;
  }

  &__likenum,
  &__report {
    display: inline-block;
  }
  &__report {
    margin-left: 29px;
    color: #222;
  }
  .bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
  }
}
</style>
