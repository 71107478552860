<template>
  <div
    v-if="commentImages.length > 0"
    class="common-reviews__list-item-pic"
  >
    <template
      v-for="(image, image_index) in commentImages"
      :key="`image__${image_index}`"
    >
      <div
        v-if="image_index <= 2"
        class="pic-item"
        tabindex="0"
        role="text"
        aria-label="picture"
        @click="clickCommentImage(comment, image_index)"
      >
        <img
          class="j-review-img lazyload"
          :data-src="transformImg({ img: image.member_image_middle || image.imgSmall })"
          :src="transformImg({ img: image.member_image_middle || image.imgSmall })"
          alt
        />
        <div
          v-if="image_index === 2 && comment.comment_image.length - 3 > 0"
          class="pic-item-more"
        >
          +
          {{ comment.comment_image.length - 3 }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { transformImg } from '@shein/common-function'
const { PUBLIC_CDN, IS_RW } = gbCommonInfo
export default {
  name: 'CommentImages',
  inject: ['COMMENT_INSTANCE'],
  data() {
    return {
      PUBLIC_CDN,
      IS_RW
    }
  },
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    commentImages () {
      // 是否展示水印图片
      const { showCommentWatermark = false } = this.COMMENT_INSTANCE?.watermarkConfig || {}
      return this.comment?.comment_image?.filter(_ => {
        if (showCommentWatermark && _?.member_image_middle && _.member_image_middle.indexOf('_wk_shein') === -1 && _.member_image_middle.indexOf('images3') > -1) {
          // 获取 member_image_middle 后缀
          const suffix = _.member_image_middle?.split('.')?.pop()
          _.member_image_middle = suffix ? _?.member_image_middle.replace(`.${suffix}`, `_wk_shein.${suffix}`) : _.member_image_middle
        }
        return _
      }) || []
    }
  },
  methods: {
    clickCommentImage(comment, index) {
      const { COMMENT_INSTANCE } = this
      COMMENT_INSTANCE.EVENT_CENTER('imageClick', { comment, index })
    },
    transformImg
  }
}
</script>
<style lang="scss">
.common-reviews__list-item-pic {
  .pic-item {
    background: url('/she_dist/images/bg-grey-ba96515e9b.png') no-repeat;
    background-size: 100%;
  }
}
</style>
