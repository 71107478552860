import commentAdapter from 'public/src/pages/goods_detail_v2/utils/adapters/comment.js'
import { makeFreeList } from './utils.js'

export default {
  reviews_abt(state) {
    return state.abt
  },
  screen_abt_tsp_config(state) {
    return state.screenAbtTspConfig
  },
  /** 首屏评论 */
  comments(state) {
    const data =
      commentAdapter.abcCommentAdapter({
        info: {
          comment_info: state.comment.comment_info
        }
      }) || []
    return data.info.commentInfo || []
  },
  /** 首屏试用报告 */
  trail_data(state) {
    const data =
      commentAdapter.freeTrailAdapter({
        info: {
          free_trial_list: state.free_trial_list?.free_trial_list
        }
      }) || []
    return makeFreeList(data.info.free_trial_list) || []
  },
  /** 首屏站外评论 */
  store_product_comments(state) {
    const store_product_comments = state.comment.store_product_comments || []
    const out_store_review_list = store_product_comments.map(
      (comment, index) => ({
        ...(comment || {}),
        isOutStoreReview: true,
        isFirstOne: index === 0,
        comment_id: comment?.store_comment_id || 0
        // page: Math.ceil((index + 1) / 20)
      })
    )

    return out_store_review_list || []
  },
  comments_overview(state) {
    // state.comment.comments_overview.commentNumShow = '100+'
    return state.comment.comments_overview || {}
  },
  comment_overview_local(state, getters) {
    const { comments_overview } = getters
    const { localSiteScore, localSiteNumShow, percent_overall_fit } = comments_overview
    // const local_percent_overall_fit = rootState.newProductDetail.CommentPopup.local_percent_overall_fit || {}
    if(+localSiteNumShow == 0 && !localSiteScore) return null

    return {
      comment_num: localSiteNumShow,
      comment_rank_average: localSiteScore,
      percent_overall_fit: {
        ...percent_overall_fit,
        // ...local_percent_overall_fit
      }
    }
  },
  // 是否展示评论时间新样式
  comment_date_display_new(state, getters) {
    const { reviews_abt } = getters
    return reviews_abt.Reviewchange?.param?.Datedisplay === 'New'
  },
  /**
   * @var LocalReviews
   * type=A | 不展示本地评论
   * type=B | 展示本地评论
   * type=C | 三期，展示本地评论，展示单条评论本地标识
   * type=D | 三期，展示本地评论，不展示单条评论本地标识，后端评论排序策略调整
   * type=E | 三期，同C，后端评论排序策略调整
   * 目前只有 A B 两种状态 cde 删除了
   */
  show_local_reviews(state, getters) {
    const { reviews_abt } = getters

    return reviews_abt?.LocalReviews?.param === 'type=B'
  },
  // 首屏评论标签
  comment_tags(state) {
    return state.comment.comment_tags || []
  },
  translate_language(state) {
    return state.comment.translate_language || []
  },
  language_config_set(state, getters) {
    const { translate_language } = getters
    return translate_language.map(item => item.translate_language) || []
  },
  // 是否批量翻译
  is_batch_translate(state, getters) {
    const { reviews_abt } = getters
    const isBatchTranslate = +state.comment.trans_mode === 1
    const isNotBatchTranslate = reviews_abt?.Reviewchange?.param?.Batch_translate === 'No'
    return isBatchTranslate && !isNotBatchTranslate
  },
  local_rating(state, getters) {
    const { comment_overview_local } = getters
    return comment_overview_local && comment_overview_local.comment_rank_average
  },
  has_fit(state, getters) {
    const { comments_overview } = getters || {}
    return +comments_overview?.hasFit
  },
  // 多维评分
  sel_tag_score(state, getters) {
    const { comments_overview } = getters
    return comments_overview?.sel_tag_score || []
  },
  bffStaticData(state) {
    return state.bffStaticData.info || {}
  },
  skc_sale_attr(state, getters) {
    return getters.bffStaticData.saleAttr?.multiLevelSaleAttribute?.skc_sale_attr || []
  },
  // 评论筛选Size数据源
  comment_filter_attr_size(state, getters) {
    const { skc_sale_attr = [] } = getters || {}
    const { attr_value_list, attr_id, attr_name } = skc_sale_attr[0] || {}
    if(!attr_value_list || +attr_id !== 87) return {}
    const newAttr = []
    // 目前只有 size 的筛选
    attr_value_list.forEach(({ attr_value_id, attr_value_name, attr_value_name_en }) => {
      newAttr.push({
        attr_id,
        attr_name,
        attr_value: attr_value_name,
        attr_value_en: attr_value_name_en,
        attr_value_id
      })
    })

    return newAttr
  },
  // color筛选
  colors_info(state, getters) {
    return getters.bffStaticData.saleAttr?.mainSaleAttribute?.info || []
  },
  // 是否显示付费评论
  show_point_program(state, getters) {
    const { reviews_abt } = getters
    return reviews_abt?.PointProgram?.param === 'type=A'
  },
  fit_map(state) {
    return {
      1: state.language.SHEIN_KEY_PC_14650,
      2: state.language.SHEIN_KEY_PC_14652,
      3: state.language.SHEIN_KEY_PC_14651,
    }
  },
  productInfo(state, getters) {
    return getters.bffStaticData?.productInfo || {}
  },
  cateInfos(state, getters) {
    const { productInfo } = getters
    const { cateIds = '', cateInfos = {} } = productInfo || {}
    
    if(!cateIds) return Object.values(cateInfos)
    // 根据cateIds排序
    const cateInfoList = []
    const cateIdsList = cateIds.split(',')
    while (cateIdsList.length) {
      cateInfoList.push(cateInfos[cateIdsList.pop()])
    }

    return cateInfoList || {}
  },
  sku_list(state, getters) {
    return getters.bffStaticData?.saleAttr?.multiLevelSaleAttribute?.sku_list
  },
  // 政策落地页
  review_policy_info(state) {
    const comment = state.comment
    return {
      reviewPolicyHasPopover: comment.reviewPolicyStyle === '1' || false,
      reviewPolicyUrl: comment.reviewPolicyUrl,
    }
  },
}
